import { extendTheme } from "@chakra-ui/react";
import { Colors, Fonts, Shadows } from "./overrides";

const theme = extendTheme({
  colors: Colors,
  fonts: Fonts,
  shadows: Shadows
});

export default theme;
