import React, { useEffect } from "react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { InputText } from "components/common/InputText";
import { Button } from "components/common/Button";
import { useRouter } from "hooks/useRouter";
import { useMetersContext } from "contexts/MetersContext";

type SearchProps = {
  onSearch: (value: string) => void;
  placeholder?: string;
};

const Search: React.FC<SearchProps> = ({ onSearch, placeholder }) => {
  const { meterId, setMeterId } = useMetersContext();
  const [value, setValue] = React.useState("");
  const router = useRouter();

  useEffect(() => {
    if (router.query.meterId) {
      setMeterId(router.query.meterId);
    } else if (meterId) {
      setMeterId("");
    }

    // eslint-disable-next-line
  }, []);

  const updateUrlParams = (value: string) => {
    let urlParams = "";
    if (router.query.date) {
      urlParams += `?date=${router.query.date}`;
      if (value) {
        urlParams += `&meterId=${value}`;
      }
    } else if (value) {
      urlParams += `?meterId=${value}`;
    }

    router.push(`${router.pathname}${urlParams}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    if (value === "") {
      setMeterId("");
      updateUrlParams("");
    }
  };

  const onSearchClick = () => {
    setMeterId(value);
    updateUrlParams(value);
  };

  return (
    <>
      <InputGroup alignItems="center">
        <InputText
          id="search"
          px={2}
          minHeight={9}
          onChange={handleChange}
          value={value || meterId}
          placeholder={placeholder}
          aria-label={placeholder}
          data-testid={`test-input`}
          isDisabled={false}
          inputSize="sm"
          pr="5.2rem"
          textTransform="uppercase"
        />
        {value.length && (
          <InputRightElement
            children={
              <Button
                position="absolute"
                top="5px"
                right="5px"
                onClick={() => onSearchClick()}
                variant="solid"
                size="sm"
              >
                Search
              </Button>
            }
          />
        )}
      </InputGroup>
    </>
  );
};

export default Search;
export type { SearchProps };