import { apiClient } from "api/APIUtils";
import { ErrorLogging } from "models/ErrorLogging";

export const logErrorAPI = async (payload: ErrorLogging): Promise<any> => {
  const response = await apiClient.post(`/error-logging`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  console.error("An error occurred", response);
  return response as any;
};
