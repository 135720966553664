export const Colors = {
  ui: {
    0: "#ffffff",
    100: "#f6f6f7",
    200: "#edeeef",
    300: "#e4e4e5",
    400: "#dbdcdd",
    500: "#c9cacd",
    600: "#b7b8bb",
    700: "#93969b",
    800: "#6f7279",
    900: "#4b4f57",
    1000: "#1e232d",
  },
  brand: {
    100: "#f5fafe",
    200: "#ecf4fd",
    300: "#e1eefb",
    400: "#d7e9fa",
    500: "#c4def8",
    600: "#b0d2f5",
    700: "#89bdf1",
    800: "#62a7eb",
    900: "#3a91e6",
    1000: "#0975e0",
    1100: "#086cce",
    1200: "#0867c5",
    1300: "#0862bc",
    1400: "#0759aa",
    1500: "#065098",
  },
  success: {
    100: "#f5faf8",
    200: "#ebf5f0",
    300: "#e0efe8",
    400: "#d6eae0",
    500: "#c2e1d1",
    600: "#add6c1",
    700: "#85c2a4",
    800: "#5cae85",
    900: "#339966",
    1000: "#008040",
    1100: "#00763b",
    1200: "#007138",
    1300: "#006c36",
    1400: "#006131",
    1500: "#00572c",
  },
  warning: {
    100: "#fdfaf7",
    200: "#faf4ee",
    300: "#f7eee5",
    400: "#f5e8dc",
    500: "#f0decb",
    600: "#ead2b9",
    700: "#e1bc97",
    800: "#d6a674",
    900: "#cc8f51",
    1000: "#bf7326",
    1100: "#b06a23",
    1200: "#a86521",
    1300: "#a06120",
    1400: "#91571d",
    1500: "#824e1a",
  },
  danger: {
    100: "#fef5f5",
    200: "#fdebeb",
    300: "#fbe0e0",
    400: "#fad6d6",
    500: "#f8c2c2",
    600: "#f5adad",
    700: "#f18585",
    800: "#eb5c5c",
    900: "#e63333",
    1000: "#e00000",
    1100: "#ce0000",
    1200: "#c50000",
    1300: "#bc0000",
    1400: "#aa0000",
    1500: "#980000",
  },
};
