import React from "react";
import {
  Alert,
  AlertProps,
  Flex,
  Box,
  CloseButton,
  useTheme,
} from "@chakra-ui/react";
import { useIcons } from "hooks/useIcons";

export type ToastStatus = "success" | "info" | "warning" | "error";

export interface ToastProps {
  status?: ToastStatus;
  title?: string;
  message?: string;
  onClose?: () => void;
}

export const Toast: React.FC<ToastProps & AlertProps> = ({
  status = "info",
  title,
  message,
  onClose,
}) => {
  const { IconTick, IconMenuClose, IconWarning } = useIcons();
  const { colors } = useTheme();

  const styles = (() => {
    switch (status) {
      case "success":
        return {
          bgColor: "success.1200",
          color: "ui.0",
          boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px ${colors.success[1200]} `,
        };
      case "warning":
        return {
          bgColor: "warning.1200",
          color: "ui.0",
          boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px ${colors.warning[1200]} `,
        };
      case "error":
        return {
          bgColor: "danger.1200",
          color: "ui.0",
          boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px ${colors.danger[1200]} `,
        };
      default:
        return {
          bgColor: "ui.0",
          boxShadow: `0 1px 4px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px ${colors.ui[300]} `,
          color: "ui.1000",
        };
    }
  })();

  const icon = (() => {
    switch (status) {
      case "success":
        return <IconTick ml={-2} mr={2} data-testid="testid-icon" />;
      case "warning":
      case "error":
        return <IconWarning ml={-2} mr={2} data-testid="testid-icon" />;
      default:
        return null;
    }
  })();

  return (
    <Alert
      status={status}
      variant="solid"
      borderRadius="6px"
      m={5}
      mt={0}
      p="18px 22px"
      alignItems="top"
      width={["250px", "500px"]}
      {...styles}
    >
      {icon}
      <Flex flexDirection="column">
        {title && (
          <Box
            fontSize="14px"
            lineHeight="18px"
            mb={message ? 2 : 0}
            fontWeight="600"
            data-testid="testid-title"
          >
            {title}
          </Box>
        )}
        {message && (
          <Box fontSize="12px" pr={10} data-testid="testid-message">
            {message}
          </Box>
        )}
      </Flex>

      <CloseButton
        data-testid="testid-close-button"
        position="absolute"
        right="12px"
        top="12px"
        onClick={onClose}
      >
        <IconMenuClose />
      </CloseButton>
    </Alert>
  );
};
