import React from "react";
import { useAppContext } from "contexts/AppContext";
import {
  listMeterConfigsAPI,
  listMetersAPI,
  listMoreMetersAPI,
} from "api/MetersAPI";
import { BranchStatus } from "components/common/Branch";
import { MeterConfig } from "models/MeterConfig";
import { getDateOffset, convertToUtc } from "utils/Utils";

export interface MetersContextProps {
  listMeterConfigs: Function;
  listMeters: Function;
  meterConfigs: MeterConfig[];
  meterList: MeterConfig[];
  loadMoreMetersLink: string;
  setLoadMoreMetersLink: Function;
  loadMoreMeters: Function;
  listMeterConfigStatus: BranchStatus;
  meterId: string;
  setMeterId: Function;
}

export const MetersContext = React.createContext<MetersContextProps>({
  listMeterConfigs: Function,
  listMeters: Function,
  meterConfigs: [],
  meterList: [],
  loadMoreMetersLink: "",
  setLoadMoreMetersLink: Function,
  loadMoreMeters: Function,
  listMeterConfigStatus: BranchStatus.Idle,
  meterId: "",
  setMeterId: Function,
});

export const useMetersContext = () => React.useContext(MetersContext);

export const MetersProvider: React.FC = ({ children }) => {
  const { instanceId } = useAppContext();
  const [meterConfigs, setMeterConfigs] = React.useState<MeterConfig[]>([]);
  const [meterList, setMeterList] = React.useState<MeterConfig[]>([]);
  const [listMeterConfigStatus, setListMeterConfigStatus] = React.useState<
    BranchStatus
  >(BranchStatus.Idle);
  const [meterId, setMeterId] = React.useState<string>("");
  const [loadMoreMetersLink, setLoadMoreMetersLink] = React.useState<string>(
    ""
  );

  const listMeters = async (date: Date) => {
    try {
      setListMeterConfigStatus(BranchStatus.Loading);
      const response = await listMetersAPI(
        instanceId,
        convertToUtc(date),
        meterId
      );
      setMeterList(response.values);

      if (!response.values.length) {
        setListMeterConfigStatus(BranchStatus.Empty);
      } else {
        setLoadMoreMetersLink(response.nextLink);
        setListMeterConfigStatus(BranchStatus.Finished);
      }
    } catch (err) {
      console.error("errored", err);
      setListMeterConfigStatus(BranchStatus.Error);
    }
  };

  const loadMoreMeters = async (url: string) => {
    try {
      const response = await listMoreMetersAPI(url);
      setMeterList([...meterList, ...response.values]);
      setLoadMoreMetersLink(response.nextLink);
    } catch (err) {
      console.error("errored", err);
      setListMeterConfigStatus(BranchStatus.Error);
    }
  };

  const listMeterConfigs = async (id: string, date: Date) => {
    try {
      setListMeterConfigStatus(BranchStatus.Loading);
      const response = await listMeterConfigsAPI(instanceId, id, date);
      setMeterConfigs(formatMeterResponse(response));
      setListMeterConfigStatus(BranchStatus.Finished);
    } catch {
      setListMeterConfigStatus(BranchStatus.Error);
    }
  };

  const formatMeterResponse = (meterConfigs: MeterConfig[]) => {
    return meterConfigs.map((meterConfig: MeterConfig) => {
      return {
        ...meterConfig,
        last_modified_timestamp: getDateOffset(meterConfig.last_modified_timestamp),
        start_timestamp: getDateOffset(meterConfig.start_timestamp)
      }
    });
  };

  return (
    <MetersContext.Provider
      value={{
        listMeterConfigs,
        listMeters,
        meterList,
        loadMoreMetersLink,
        setLoadMoreMetersLink,
        loadMoreMeters,
        meterConfigs,
        listMeterConfigStatus,
        meterId,
        setMeterId,
      }}
    >
      {children}
    </MetersContext.Provider>
  );
};
