import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import Logo from "images/energysys-logo.png";

export const LandingLayout: React.FC = ({ children }) => {
  return (
    <Flex
      justifyContent="center"
      minHeight="100vh"
      overflow="scroll"
      backgroundColor="ui.100"
    >
      <Box
        height="fit-content"
        width="85vw"
        minWidth="500px"
        maxWidth="800px"
        borderWidth={"1px"}
        p="8"
        my="8"
        mx="4"
        borderRadius="6px"
        backgroundColor="ui.0"
      >
        <Image src={Logo} height="35px" mb="4" />
        {children}
      </Box>
    </Flex>
  );
};
