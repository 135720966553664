import React from "react";
import { setDefaultSelectedDay } from "utils/Utils";

export interface DateContextProps {
  selectedDate: Date;
  setSelectedDate: Function;
  fromDate: Date;
  setFromDate: Function;
  toDate: Date;
  setToDate: Function;
  showDateRange: boolean;
  setShowDateRange: Function;
}

export const DateContext = React.createContext<DateContextProps>({
  selectedDate: setDefaultSelectedDay(),
  setSelectedDate: Function,
  toDate: setDefaultSelectedDay(),
  setToDate: Function,
  fromDate: setDefaultSelectedDay(),
  setFromDate: Function,
  showDateRange: false,
  setShowDateRange: Function,
});

export const useDateContext = () => React.useContext(DateContext);

export const DateProvider: React.FC = ({ children }) => {
  const [selectedDate, setSelectedDate] = React.useState<any>(
    setDefaultSelectedDay()
  );
  const [fromDate, setFromDate] = React.useState<any>(setDefaultSelectedDay());
  const [toDate, setToDate] = React.useState<any>(setDefaultSelectedDay());
  const [showDateRange, setShowDateRange] = React.useState<boolean>(false);

  return (
    <DateContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        showDateRange,
        setShowDateRange,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
