import React from "react";
import { Box, Button, ButtonProps } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

type Props = {
  icon?: any;
  to: string;
};

export const NavItem: React.FC<Props & ButtonProps> = ({
  children,
  icon,
  to,
}) => {
  const history = useHistory();
  const location = useLocation();
  const active = location.pathname === to;

  return (
    <Box mr={2}>
      <Button
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        p={6}
        mr={5}
        borderLeft={"4px"}
        borderLeftColor={active ? "brand.1000" : "ui.0"}
        fontWeight={active ? 500 : 400}
        fontSize="14px"
        isFullWidth
        justifyContent="left"
        backgroundColor={active ? "brand.100" : "ui.0"}
        leftIcon={icon && <Box mt={1}>{icon}</Box>}
        letterSpacing="-0.09px"
        color={active ? "brand.1000" : "ui.900"}
        _hover={{
          bg: "brand.100",
          borderLeftColor: "brand.1000",
          color: "brand.1000",
        }}
        onClick={() => {
          !active && history.push(to);
        }}
      >
        {children}
      </Button>
    </Box>
  );
};
