import React from "react";
import { Loading } from "components/common/Loading";
import { Error } from "components/common/Error";
import { Empty } from "components/common/Empty";

export enum BranchStatus {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Finished = "finished",
  Empty = "empty",
}

interface IBranch {
  isLoading?: boolean;
  isEmpty?: boolean;
  hasError?: boolean;
  status?: string;
  ErrorComponent?: () => React.ReactElement;
  EmptyComponent?: () => React.ReactElement;
  LoadingComponent?: () => React.ReactElement;
  Component: () => React.ReactElement;
}

export const Branch: React.FC<IBranch> = ({
  Component,
  LoadingComponent = () => <Loading />,
  EmptyComponent = () => <Empty />,
  ErrorComponent = () => <Error />,
  status,
  hasError = false,
  isLoading = false,
  isEmpty = false,
}) => (
  <>
    {hasError || status === BranchStatus.Error
      ? ErrorComponent()
      : isLoading || status === BranchStatus.Loading
      ? LoadingComponent()
      : isEmpty || status === BranchStatus.Idle || status === BranchStatus.Empty
      ? EmptyComponent()
      : Component()}
  </>
);
