import React from "react";
import { Flex, Input, InputProps, FormLabel } from "@chakra-ui/react";

type InputSize = "sm" | "md";

interface InputTextOverride {
  fontSize: string;
  height: string;
}

// override the ChakraUI theme to match the designs
const theme = (fontSize: any): InputTextOverride => {
  switch (fontSize) {
    case "sm":
      return {
        fontSize: "12px",
        height: "24px",
      };
    case "md":
    default:
      return {
        fontSize: "14px",
        height: "32px",
      };
  }
};

type Props = {
  id: string;
  label?: string;
  validationError?: string;
  inputSize?: InputSize;
};

export const InputText: React.FC<Props & InputProps> = ({
  id,
  label,
  validationError,
  inputSize = "md",
  ...props
}) => {
  return (
    <>
      {label && (
        <FormLabel
          data-testid={`testid-${id}-label`}
          color="ui.1000"
          fontSize="12px"
          lineHeight="16px"
          fontWeight="400"
          htmlFor={id}
        >
          {label}
        </FormLabel>
      )}
      <Input
        id={id}
        data-testid={`testid-${id}-input`}
        borderColor="ui.300"
        focusBorderColor="brand.1000"
        borderWidth="1px"
        borderRadius="6px"
        errorBorderColor="danger.1000"
        padding={2}
        fontWeight="400"
        fontSize={theme(inputSize).fontSize}
        height={theme(inputSize).height}
        color="ui.1000"
        isInvalid={!!validationError}
        {...props}
      />
      {!!validationError && (
        <Flex
          data-testid={`testid-${id}-validation-error`}
          as="div"
          fontSize="10px"
          lineHeight="16px"
          color="danger.1000"
          m={1}
          className="field-error"
        >
          {validationError}
        </Flex>
      )}
    </>
  );
};
