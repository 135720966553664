import React from "react";
import { Skeleton, Box } from "@chakra-ui/react";

export const MeterDrawerLoading: React.FC = () => (
  <>
    {[...Array(6)].map((_, i) => {
      const headingWidth = Math.random() * (100 - 50) + 50;

      return (
        <Box my={8} key={`skeleton-row-${i}`}>
          <Skeleton
            height={3}
            my={2}
            borderRadius={4}
            startColor="ui.200"
            endColor="ui.400"
            width={`${headingWidth}px`}
          />
          <Skeleton
            height={8}
            my={2}
            borderRadius={4}
            startColor="ui.100"
            endColor="ui.200"
          />
        </Box>
      );
    })}
  </>
);
