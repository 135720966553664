import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { Heading } from "components/common/Heading";
import { Header } from "components/layout/Header";
import { useAppContext } from "contexts/AppContext";

interface Props {
  title: string;
}

export const PageLayout: React.FC<Props> = ({ title, children }) => {
  const { headerHeight, setHeaderHeight } = useAppContext();
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      setHeaderHeight(Math.floor(ref.current.getBoundingClientRect().top));
    }
  }, [ref, setHeaderHeight]);

  return (
    <Flex flexDirection="column" height="100vh" width="100vw">
      <Header>
        <Flex justifyContent="space-between" flex="1 0 auto">
          <Box mx={5} alignSelf="center">
            <Heading as="h2" size="xxs" mt={1} color="ui.0">
              {title}
            </Heading>
          </Box>
        </Flex>
      </Header>
      <Flex height={`calc(100vh - ${headerHeight}px)`} ref={ref}>
        {children}
      </Flex>
    </Flex>
  );
};
