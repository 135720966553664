import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "@chakra-ui/react";
import { Button } from "components/common/Button";
import { Text } from "components/common/Text";

interface ErrorProps {
  retry?: () => void;
}

export const Error: React.FC<ErrorProps> = ({ retry }) => {
  const { t } = useTranslation();

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt="0"
    >
      <Text fontWeight="500" pb={2}>
        {t("common.error.fullPageError.title")}
      </Text>
      <Text fontSize="md">{t("common.error.fullPageError.message")}</Text>

      {retry && (
        <Box mt={6}>
          <Button onClick={retry}>
            {t("common.error.fullPageError.buttonText")}
          </Button>
        </Box>
      )}
    </Flex>
  );
};
