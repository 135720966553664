import React from "react";
import { Instance } from "models/Instance";
import { Preferences } from "models/Preferences";
import {
  listInstancesAPI,
  getPreferencesAPI,
  getMetaData,
} from "api/ApplicationAPI";
import { BranchStatus } from "components/common/Branch";
import XMLParser from "react-xml-parser";

export interface AppContextProps {
  initApp: Function;
  initAppStatus: BranchStatus;
  instances: Instance[];
  instanceId: string;
  dashboardId: string;
  setInstanceId: Function;
  userPermissions: string;
  user: string;
  preferences: Preferences | null;
  metaData: any;
  headerHeight: number;
  setHeaderHeight: Function;
  showModal: boolean;
  setShowModal: Function;
}

export const AppContext = React.createContext<AppContextProps>({
  initApp: Function,
  initAppStatus: BranchStatus.Idle,
  instances: [],
  instanceId: "",
  dashboardId: "",
  setInstanceId: Function,
  userPermissions: "",
  user: "",
  preferences: null,
  metaData: null,
  headerHeight: 0,
  setHeaderHeight: Function,
  showModal: false,
  setShowModal: Function,
});

export const useAppContext = () => React.useContext(AppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [instances, setInstances] = React.useState<Instance[]>([]);
  const [instanceId, setInstanceId] = React.useState<string>("");
  const [user, setUser] = React.useState<string>("");
  const [dashboardId, setDashboardId] = React.useState<string>("");
  const [userPermissions, setUserPermissions] = React.useState<string>("");
  const [preferences, setPreferences] = React.useState<Preferences | null>(
    null
  );
  const [metaData, setMetaData] = React.useState<any>(null);
  const [initAppStatus, setInitAppStatus] = React.useState<BranchStatus>(
    BranchStatus.Idle
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const initApp = async () => {
    setUser(window.localStorage.getItem("user") || "");
    try {
      setInitAppStatus(BranchStatus.Loading);
      const instancesResponse = await listInstancesAPI();
      instancesResponse.sort((a: Instance, b: Instance) =>
        b.name < a.name ? 1 : a.name < b.name ? -1 : 0
      );
      setInstances(instancesResponse);
      if (instancesResponse.find((x: Instance) => x.id !== instanceId)) {
        const cachedInstanceId = localStorage.getItem("instanceId");
        const selectedInstanceId = instancesResponse.find(
          (x: Instance) => x.id === cachedInstanceId
        )
          ? cachedInstanceId
          : instancesResponse[0].id;
        setInstanceId(selectedInstanceId || "");

        localStorage.setItem("instanceId", selectedInstanceId || "");
        setUserPermissions(
          instancesResponse[0].roles.ReadOnly !== undefined
            ? "ReadOnly"
            : "ReadWrite"
        );
        setDashboardId(
          instancesResponse.find((x: Instance) => x.id === selectedInstanceId)
            ?.dashboard || ""
        );
      }

      try {
        setInitAppStatus(BranchStatus.Loading);
        const preferencesResponse = await getPreferencesAPI(
          instancesResponse[0].id
        );
        setPreferences(preferencesResponse);

        try {
          setInitAppStatus(BranchStatus.Loading);
          const metaDataResponse = await getMetaData(instancesResponse[0].id);
          const parsedXML = new XMLParser().parseFromString(metaDataResponse);

          setMetaData(parsedXML.getElementsByTagName("EntityType"));
        } catch (err) {
          console.error(
            "Error occurred getting meta data - failed silently",
            err
          );
        }
      } catch {
        setInitAppStatus(BranchStatus.Error);
      }
      setInitAppStatus(BranchStatus.Finished);
    } catch {
      setInitAppStatus(BranchStatus.Error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        initApp,
        initAppStatus,
        instances,
        instanceId,
        dashboardId,
        setInstanceId,
        userPermissions,
        user,
        preferences,
        metaData,
        headerHeight,
        setHeaderHeight,
        showModal,
        setShowModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
