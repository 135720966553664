import React from "react";
import {
  Tabs as ChakraUITabs,
  TabList as ChakraUITabList,
  Tab as ChakraUITab,
  TabPanels as ChakraUITabPanels,
  TabPanel as ChakraUiTabPanel,
  Box,
} from "@chakra-ui/react";

interface Tab {
  label: string;
  content: JSX.Element;
}

interface Props {
  tabs: Tab[];
  bg?: string;
}

export const Tabs: React.FC<Props> = ({ tabs, bg }) => (
  <>
    <ChakraUITabs height="100%">
      <Box
        bgColor={bg || "ui.0"}
        width="100%"
        position="fixed"
        my={-10}
        padding="0 1rem"
        borderBottom="1px solid"
        borderBottomColor="ui.300"
      >
        <ChakraUITabList border="none" mb={"2px"}>
          {tabs.map(({ label }, j) => (
            <ChakraUITab
              key={j}
              fontSize="14px"
              fontWeight="400"
              px={1}
              mr={2}
              color="ui.0"
              _selected={{
                color: "ui.0",
                borderBottom: "2px",
                borderBottomColor: "ui.0",
              }}
              _disabled={{
                color: "brand.700",
              }}
            >
              {label}
            </ChakraUITab>
          ))}
        </ChakraUITabList>
      </Box>
      <ChakraUITabPanels height="100%">
        {tabs.map(({ content }, k) => (
          <ChakraUiTabPanel key={k} height="100%">
            {content}
          </ChakraUiTabPanel>
        ))}
      </ChakraUITabPanels>
    </ChakraUITabs>
  </>
);
