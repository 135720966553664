import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { Text } from "components/common/Text";

export const Empty: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontWeight="500" pb={2}>
        {t("common.empty.noDataReturnedError.title")}
      </Text>
      <Text fontSize="md">{t("common.empty.noDataReturnedError.message")}</Text>
    </Flex>
  );
};
