import React, { forwardRef } from "react";
import {
  Button as ChakraUIButton,
  ButtonProps as ChakrUIButtonProps,
} from "@chakra-ui/react";
import theme from "theme";

export type ButtonVariant = "solid" | "outline" | "ghost" | "gradient";
export type ButtonSize = "md" | "sm" | "xs";

interface ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  testId?: string;
  variant?: ButtonVariant;
  padding?: string;
  ref?: any;
  mt?: string;
}

export const Button: React.FC<ButtonProps & ChakrUIButtonProps> = forwardRef(({
  children,
  disabled = false,
  loading = false,
  onClick,
  size = "md",
  testId = `testid-button-${children}`,
  variant = "solid",
  padding,
  ...props
}, ref: any) => {
  const { colors } = theme;

  const sizes = () => {
    switch (size) {
      case "sm":
        return { fontSize: "14px", padding: "3px 8px" };
      case "xs":
        return { fontSize: "12px", padding: "2px 8px" };
      default:
        return { fontSize: "16px", padding: "5px 10px" };
    }
  };

  const styles = () => {
    switch (variant) {
      case "outline":
        return {
          bg: "ui.0",
          boxShadow: `0 0 0 1px ${colors.ui[400]}`,
          color: "ui.1000",
          hover: {
            bg: "ui.200",
          },
          focus: {
            boxShadow: `0 0 0 4px ${colors.brand[700]}`,
          },
          active: {
            bg: "ui.300",
          },
          disabled: {
            opacity: 0.4,
            bg: "ui.200",
          },
        };
      case "ghost":
        return {
          bg: "transparent",
          boxShadow: "none",
          color: "ui.1000",
          hover: {
            bg: "ui.200",
          },
          focus: {
            boxShadow: `0 0 0 4px ${colors.brand[700]}`,
          },
          active: {
            bg: "ui.300",
          },
          disabled: {
            opacity: 0.4,
          },
        };
      case "gradient": {
        return {
          bg: "ui.100",
          boxShadow: "none",
          color: "ui.1000",
          hover: {
            bg: "ui.200",
          },
          focus: {
            boxShadow: `0 0 0 4px ${colors.brand[700]}`,
          },
          active: {
            bg: "ui.300",
          },
          disabled: {
            opacity: 0.4,
          },
        };
      }
      default:
        return {
          bg: "brand.1000",
          boxShadow: "none",
          color: "white",
          hover: {
            bg: "brand.1100",
          },
          focus: {
            boxShadow: `0 0 0 4px ${colors.brand[700]}`,
          },
          active: {
            bg: "brand.1400",
          },
          disabled: {
            opacity: 0.4,
          },
        };
    }
  };

  return (
    <ChakraUIButton
      bg={styles().bg}
      border="none"
      boxShadow={styles().boxShadow}
      color={styles().color}
      data-testid={`button-${testId}`}
      fontSize={sizes().fontSize}
      fontWeight="400"
      height="auto"
      isDisabled={disabled}
      isLoading={loading}
      lineHeight="unset"
      onClick={onClick}
      borderRadius="6px"
      ref={ref}
      p={padding ? padding : sizes().padding}
      _hover={{
        ...styles().hover,
        _focus: { ...styles().focus },
      }}
      _focus={{
        ...styles().focus,
        _hover: { ...styles().hover },
        _active: { ...styles().active },
      }}
      _active={{
        ...styles().active,
      }}
      _disabled={{
        ...styles().disabled,
      }}
      {...props}
    >
      {children}
    </ChakraUIButton>
  );
});
