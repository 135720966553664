import moment from "moment";

export const getUid = () => {
  let d = new Date().getTime();
  return "xxxxxxxxxxxxxxxx".replace(/x/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export const getDateOffset = (date: any) => {
  const str = date.toString();
  const tokens = str.split("-");

  if (tokens.length > 1) {
    const dayTime = tokens[2].split("T");
    const str1 = `${tokens[0]}-${tokens[1]}-${dayTime[0]}`;
    const value =
      moment(str1).format("D/MMM/yyyy") + " " + dayTime[1].split(".")[0];

    return value;
  }
  return str;
};

export const convertToUtc = (date: Date) => {
  return moment(
    moment(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
    moment.defaultFormat
  ).toDate();
};

export const setDefaultSelectedDay = () => {
  return new Date(Date.now() - 1 * 24 * 3600 * 1000); // today, minus one day
};

export const getBuildDate = (epoch: any) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};
