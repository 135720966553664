import React from "react";
import { useTranslation } from "react-i18next";

import { EventSourceLoggingProvider } from "contexts/EventSourceLoggingContext";
import { LandingLayout } from "components/layout/LandingLayout";
import { EventSourceForm } from "components/forms/EventSourceForm";
import { Heading } from "components/common/Heading";

export const EventSourcePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EventSourceLoggingProvider>
      <LandingLayout>
        <Heading as="h5" mb="2">{t("pages.eventSource.title")}</Heading>
        <EventSourceForm />
      </LandingLayout>
    </EventSourceLoggingProvider>
  );
};
