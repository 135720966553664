import React from "react";
import { useToast } from "@chakra-ui/react";
import { Toast, ToastProps } from "components/common/Toast";

export interface ToastContextProps {
  setToast: Function;
}

export const ToastContext = React.createContext<ToastContextProps>({
  setToast: Function,
});

export const useToasts = () => React.useContext(ToastContext);

export const ToastProvider: React.FC = ({ children }) => {
  const showToast = useToast();

  return (
    <ToastContext.Provider
      value={{
        setToast: ({ title, message, status }: ToastProps) =>
          showToast({
            position: "bottom-left",
            isClosable: true,
            duration: 4000,
            render: ({ onClose }) => (
              <Toast
                status={status}
                title={title}
                message={message}
                onClose={onClose}
              />
            ),
          }),
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
