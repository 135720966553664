import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "components/layout/AppLayout";
import { MeterDrawer } from "components/meters/MeterDrawer";
import EMSGrid from "components/common/EMSGrid";
import { Skeleton } from "components/common/Skeleton";
import { Branch } from "components/common/Branch";
import { Error } from "components/common/Error";
import { Empty } from "components/common/Empty";
import { useAppContext } from "contexts/AppContext";
import { useDataContext } from "contexts/DataContext";
import { useMetersContext } from "contexts/MetersContext";
import { useDateContext } from "contexts/DateContext";

export const DailyMeasurementsPage: React.FC = () => {
  const { t } = useTranslation();
  const { metaData, preferences, instanceId } = useAppContext();
  const [initialFetch, setInitialFetch] = React.useState(true);
  const [entityType, setEntityType] = React.useState(null);

  const {
    listDailyMeasurements,
    listMeasurementsStatus,
    measurements,
    getMoreMeasurements,
    getMoreMeasurementsUrl,
  } = useDataContext();
  const { meterId } = useMetersContext();
  const { selectedDate, setShowDateRange, showDateRange } = useDateContext();

  const fetchData = React.useCallback(async () => {
    listDailyMeasurements(selectedDate, meterId);
    setInitialFetch(false);
  }, [listDailyMeasurements, selectedDate, meterId]);

  const fetchMoreData = async (url: string) => {
    return await getMoreMeasurements(url);
  };

  React.useEffect(() => {
    if (metaData) {
      setEntityType(metaData.find(
        (y: any) => y.attributes.Name === "Measurement"
      ));
    }
  }, [metaData]);

  React.useEffect(() => {
    // Checks instanceId (tenant endpoint) has been hit before fetching data.
    if (instanceId) {
      if (
        (initialFetch &&
          (window.location.href.includes(meterId) || meterId === "")) ||
        !initialFetch
      ) {
        fetchData();
      }
    }

    // eslint-disable-next-line
  }, [JSON.stringify(selectedDate), meterId]);

  React.useEffect(() => {
    setShowDateRange(false);
  }, [showDateRange, setShowDateRange]);

  return (
    <AppLayout
      title={t("pages.dailyMeasurements.title")}
      onSearch={fetchData}
      showActions={true}
    >
      <Branch
        isLoading={listMeasurementsStatus === "loading"}
        LoadingComponent={() => <Skeleton header rows={8} cols={6} />}
        hasError={listMeasurementsStatus === "error"}
        ErrorComponent={() => <Error retry={fetchData} />}
        isEmpty={listMeasurementsStatus === "empty"}
        EmptyComponent={() => <Empty />}
        Component={() => (
          <EMSGrid
            data={measurements}
            preferences={preferences?.DailyMeasurement}
            entityType={entityType}
            fetchData={fetchData}
            fetchMoreData={fetchMoreData}
            loadMoreLink={getMoreMeasurementsUrl}
          />
        )}
      />
      <MeterDrawer />
    </AppLayout>
  );
};
