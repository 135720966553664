import React from "react";
import { useTranslation } from "react-i18next";
import { MeterConfig } from "models/MeterConfig";
import { Flex, Box } from "@chakra-ui/react";
import { Text } from "components/common/Text";

interface Props {
  configs?: MeterConfig[] | null;
}
export const MeterConfiguration: React.FC<Props> = ({ configs }) => {
  const { t } = useTranslation();
  const EXCLUDED_FIELDS = ["PK", "SK", "meter_id", "version"];

  const getValue = (o: any) => {
    switch (typeof o) {
      case "object":
        return o.value;
      default:
        return o.toString();
    }
  };

  if (!configs?.length) {
    return <Text my={6}>{t("components.meterDrawer.noConfig")}</Text>;
  } else {
    return (
      <Flex flexWrap="wrap">
        {Object.entries(configs[0]).map((o, i) => {
          const key = o[0];
          return (
            !EXCLUDED_FIELDS.includes(key) &&
            o[1] !== null && (
              <Box key={i} flexGrow={1} width="50%" mb={4} pr={4}>
                <Text fontSize="sm" fontWeight="500" mb={2}>
                  {t(`headings.${key}`)}
                </Text>
                <Text fontSize="md" bgColor="ui.100" fontWeight="400" px={3} py={2}>
                  {getValue(o[1])}
                </Text>
              </Box>
            )
          );
        })}
      </Flex>
    );
  }
};
