import React from "react";
import { Flex } from "@chakra-ui/react";
import { Branch } from "components/common/Branch";
import EMSGrid from "components/common/EMSGrid";
import { Skeleton } from "components/common/Skeleton";
import { Error } from "components/common/Error";
import { Empty } from "components/common/Empty";
import { useDataContext } from "contexts/DataContext";
import { useAppContext } from "contexts/AppContext";
import { useGridContext } from "contexts/GridContext";
import { useRouter } from "hooks/useRouter";

export const VersionHistory: React.FC = () => {
  const { metaData, preferences } = useAppContext();
  const {
    listVersionMeasurements,
    listVersionMeasurementsStatus,
    versionMeasurements,
    getMoreMeasurements,
    getMoreMeasurementsUrl,
  } = useDataContext();
  const { selectedRows } = useGridContext();
  const router = useRouter();
  const [entityType, setEntityType] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    const contractDay = router.pathname.includes("hourly-measurement-history")
      ? selectedRows[0].start_timestamp
      : selectedRows[0].contract_day;
    const period = router.pathname.includes("hourly-measurement-history")
      ? "hourly"
      : "daily";
    const meterId = selectedRows[0].meter_id;
    listVersionMeasurements(contractDay, period, meterId);
  }, [listVersionMeasurements, selectedRows, router]);

  const fetchMoreData = async (url: string) => {
    getMoreMeasurements(url);
  };

  // When reloading the meterId will need to be set before fetching data.
  React.useEffect(() => {
    selectedRows.length && fetchData();

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (metaData) {
      setEntityType(metaData.find(
        (y: any) => y.attributes.Name === "Measurement"
      ));
    }
  }, [metaData]);

  return (
    <Branch
      isLoading={listVersionMeasurementsStatus === "loading"}
      LoadingComponent={() => <Skeleton header rows={8} cols={6} />}
      hasError={listVersionMeasurementsStatus === "error"}
      ErrorComponent={() => <Error retry={fetchData} />}
      isEmpty={listVersionMeasurementsStatus === "empty"}
      EmptyComponent={() => <Empty />}
      Component={() => (
        <Flex
          flexDirection="column"
          width="100%"
          border="1px solid"
          borderColor="ui.400"
          marginTop="-1px"
          pos="relative"
        >
          <EMSGrid
            data={versionMeasurements.sort((a, b) =>
              a.version < b.version ? 1 : b.version < a.version ? -1 : 0
            )}
            preferences={
              router.pathname.includes("measurement-history")
                ? preferences?.HourlyMeasurement_VersionHistory
                : preferences?.DailyMeasurement_VersionHistory
            }
            entityType={entityType}
            fetchData={fetchData}
            height="383px"
            fetchMoreData={fetchMoreData}
            loadMoreLink={getMoreMeasurementsUrl}
            disableContextMenu={true}
          />
        </Flex>
      )}
    />
  );
};
