import React from "react";
import { logErrorAPI } from "api/ErrorLoggingAPI";
import { ErrorLogging } from "models/ErrorLogging";

export interface ErrorLoggingContextProps {
  logError: Function;
}

export const ErrorLoggingContext = React.createContext<
  ErrorLoggingContextProps
>({
  logError: Function,
});

export const useErrorLoggingContext = () =>
  React.useContext(ErrorLoggingContext);

export const ErrorLoggingProvider: React.FC = ({ children }) => {
  const logError = async (error: ErrorLogging) => {
    try {
      await logErrorAPI(error);
      return "success";
    } catch (err) {
      console.error("errored", err);
    }
  };

  return (
    <ErrorLoggingContext.Provider
      value={{
        logError,
      }}
    >
      {children}
    </ErrorLoggingContext.Provider>
  );
};
