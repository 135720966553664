import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { DatePicker } from "components/common/DatePicker/DatePicker";
import { ActionsMenu } from "components/common/ActionsMenu";
import Search from "components/common/Search";

import { useTranslation } from "react-i18next";

interface Props {
  onSearch?: () => void;
  showActions?: boolean;
  showFilter?: boolean;
}

export const ControlsBar: React.FC<Props> = ({
  onSearch,
  showActions = false,
  showFilter = false,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      py={2}
      px={6}
      fontSize="12px"
      backgroundColor="ui.0"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="ui.400"
    >
      <Box width="15.5rem">
        {onSearch && !showFilter && (
          <Search
            onSearch={onSearch}
            placeholder={t("components.search.placeholder")}
          />
        )}
      </Box>
      <Box zIndex="4">
        <DatePicker />
      </Box>
      <Box width="15.5rem" textAlign="right">
        {showActions && <ActionsMenu />}
      </Box>
    </Flex>
  );
};
