import React from "react";
import { useTranslation } from "react-i18next";
import { Button, MenuButton, MenuList, Menu, MenuItem } from "@chakra-ui/react";
import { useGridContext } from "contexts/GridContext";
import { useContextMenuItems } from "hooks/useContextMenuItems";
import { useContextActions } from "hooks/useContextActions";
import { BiChevronDown } from "react-icons/bi";

interface ActionItemProps {
  onClick?: () => void;
  label: string;
  disabled?: boolean;
}

const ActionItem: React.FC<ActionItemProps> = (props) => (
  <MenuItem fontSize="14px" {...props}>
    {props.label}
  </MenuItem>
);

export const ActionsMenu: React.FC = () => {
  const { t } = useTranslation();
  const { selectedRows } = useGridContext();
  const contextMenuItems = useContextMenuItems();
  const { contextActions } = useContextActions();

  return (
    <Menu>
      <MenuButton
        as={Button}
        h={8}
        px={3}
        borderRadius="3"
        fontSize="sm"
        fontWeight={400}
        bgColor="brand.1000"
        color="ui.0"
        _active={{ bg: "brand.1200" }}
        _hover={{ bg: "brand.1100" }}
        rightIcon={<BiChevronDown size="1.2em" />}
      >
        {t("components.actions.label")}&nbsp;
      </MenuButton>
      <MenuList zIndex="102">
        {contextMenuItems &&
          contextMenuItems.map(({ header, cmd }) => (
            <ActionItem
              key={header}
              label={`${header}${cmd === "recalculateRowTrigger" && selectedRows.length > 1
                ? "s"
                : ""
                }`}
              disabled={
                selectedRows.length !== 1 && cmd !== "recalculateRowTrigger"
              }
              onClick={() => contextActions(cmd, selectedRows[0])}
            />
          ))}
      </MenuList>
    </Menu>
  );
};
