import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import Logo from "images/energysys-logo.png";

export const LoginLayout: React.FC = ({ children }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor="ui.100"
    >
      <Box
        width="320px"
        borderWidth={"1px"}
        py="10"
        px="8"
        borderRadius="6px"
        backgroundColor="ui.0"
      >
        <Image src={Logo} height="35px" />
        {children}
      </Box>
    </Flex>
  );
};
