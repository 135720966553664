import { apiClient } from "api/APIUtils";
import { Instance } from "models/Instance";

// TODO: Rewrite the types for the fetch
// export const listInstancesAPI = async (): Promise<Instance[]> => {
export const listInstancesAPI = async (): Promise<any> => {
  const MAX_TRIES = 2;
  const fetch = async () => {
    for (let count = 1; count <= MAX_TRIES; count++) {
      try {
        const response = await apiClient.get(`/tenant`);
        return response.data.instances as Instance[];
      } catch (err) {
        if (count < MAX_TRIES) {
          continue;
        } else {
          return err;
        }
      }
    }
  };

  return await fetch();
};

export const getPreferencesAPI = async (instanceId: string): Promise<any> => {
  const response = await apiClient.get(`/${instanceId}/preferences`);
  return response.data;
};

export const getMetaData = async (instanceId: string): Promise<any> => {
  const response = await apiClient.get(`/${instanceId}/$metadata`);
  return response.data;
};
