import React from "react";
import { Box, Link } from "@chakra-ui/react";
import { FaUserCog, FaQuestionCircle } from "react-icons/fa";

export const IconList: React.FC = () => {
  return (
    <>
      <Box px="2" py="1">
        <Link href="https://support.energysys.com/support/solutions" isExternal>
          <FaUserCog color="white" size="1.5em" />
        </Link>
      </Box>
      <Box pl="2" py="1">
        <Link href="https://id.energysys.com/enduser/settings" isExternal>
          <FaQuestionCircle color="white" size="1.5em" />
        </Link>
      </Box>
    </>
  );
};
