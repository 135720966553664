import React from "react";
import { Flex } from "@chakra-ui/react";
import { Nav } from "components/layout/Nav";
import { GlobalActionsWrapper } from "components/GlobalActionsPanel/GlobalActionWrapper";

export const Header: React.FC = ({ children }) => {
  return (
    <Flex
      px={6}
      py={0}
      borderBottom="1px"
      borderBottomColor="brand.1000"
      backgroundColor="brand.1000"
      minHeight="64px"
      alignItems="center"
    >
      <Nav />
      {children}
      <GlobalActionsWrapper />
    </Flex>
  );
};
