import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { LicenseManager } from "ag-grid-enterprise";

import { PrivateRoute } from "components/PrivateRoute";
import { Branch, BranchStatus } from "components/common/Branch";
import { Error } from "components/common/Error";

import { useAuth } from "hooks/useAuth";
import { useAppContext } from "contexts/AppContext";
import withClearCache from "ClearCache";
import {
  DailyMeasurementsPage,
  EventSourcePage,
  LoginPage,
  MeasurementHistoryPage,
  QuicksightDashboardPage,
} from "pages";

import AgGridLicense from "AgGridLicense.json";

LicenseManager.setLicenseKey(AgGridLicense.key);

const MainApp: React.FC = () => {
  const { createAuthTokens, redirect } = useAuth();
  const { initApp, initAppStatus } = useAppContext();

  const fetchData = React.useCallback(async () => {
    initApp();
  }, [initApp]);

  React.useEffect(() => {
    // if auth code grant flow tokens are in the URL then
    // exchange the URL code for auth tokens.
    if (window.location.search.indexOf("code=") > -1) {
      createAuthTokens();
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Flex flexDirection="column" height="100vh" width="100vw">
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/dashboard">
            <Branch
              isLoading={initAppStatus === BranchStatus.Loading}
              hasError={initAppStatus === BranchStatus.Error}
              ErrorComponent={() => <Error retry={fetchData} />}
              Component={() => <QuicksightDashboardPage />}
            />
          </PrivateRoute>
          <PrivateRoute path="/event-source">
            <Branch
              isLoading={initAppStatus === BranchStatus.Loading}
              hasError={initAppStatus === BranchStatus.Error}
              ErrorComponent={() => <Error retry={fetchData} />}
              Component={() => <EventSourcePage />}
            />
          </PrivateRoute>
          <PrivateRoute path="/:instanceId/daily-measurements" exact>
            <Branch
              isLoading={initAppStatus === BranchStatus.Loading}
              hasError={initAppStatus === BranchStatus.Error}
              ErrorComponent={() => <Error retry={fetchData} />}
              Component={() => <DailyMeasurementsPage />}
            />
          </PrivateRoute>
          <PrivateRoute
            path="/:instanceId/:meterId/hourly-measurement-history"
            exact
          >
            <Branch
              isLoading={initAppStatus === BranchStatus.Loading}
              hasError={initAppStatus === BranchStatus.Error}
              ErrorComponent={() => <Error retry={fetchData} />}
              Component={() => <MeasurementHistoryPage timePeriod="hourly" />}
            />
          </PrivateRoute>
          <PrivateRoute
            path="/:instanceId/:meterId/daily-measurement-history"
            exact
          >
            <Branch
              isLoading={initAppStatus === BranchStatus.Loading}
              hasError={initAppStatus === BranchStatus.Error}
              ErrorComponent={() => <Error retry={fetchData} />}
              Component={() => <MeasurementHistoryPage timePeriod="daily" />}
            />
          </PrivateRoute>
          {redirect && <Redirect from="*" to={redirect} />}
        </Switch>
      </Router>
    </Flex>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const App: React.FC = () => <ClearCacheComponent />;

export default App;
