import React from "react";
import { Link } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

interface IconLinkProps {
  to: string;
  external?: boolean;
  testId?: string;
}

const IconLink: React.FC<IconLinkProps> = ({
  children,
  to,
  external = false,
  testId
}) => {
  return (
    <Link href={to} isExternal={external} color="brand.1300" data-testid={`icon-link-${testId}`}>
      {children}
      {(external) && (
        <FaExternalLinkAlt size="12" style={{ position: "relative", top: "-2px", display: "inline", marginLeft: "4px", color: "#0862bc" }} />
      )}
    </Link>
  );
};

export default IconLink;
export type { IconLinkProps };