import React from "react";
import { useLocation } from "react-router-dom";

import { LoginLayout } from "components/layout/LoginLayout";
import { LoginForm } from "components/forms/LoginForm";
import { useAuth } from "hooks/useAuth";

export const LoginPage: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location: any = useLocation();

  const getFullPath = (): string => {
    const referrer = location.state.referrer;
    const fullPath = `${referrer.pathname}${referrer.search}${referrer.hash}`;
    return fullPath;
  }

  if (location?.state?.referrer && !isAuthenticated()) {
    sessionStorage.setItem("redirectPath", getFullPath());
  }

  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
};
