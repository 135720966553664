import { useRouter } from "hooks/useRouter";
import { useMetersContext } from "contexts/MetersContext";
import { useDateContext } from "contexts/DateContext";
import { useAppContext } from "contexts/AppContext";
import { useGridContext } from "contexts/GridContext";
import { convertToUtc } from "utils";

export function useContextActions() {
  const router = useRouter();
  const { setShowModal, instanceId } = useAppContext();
  const { setFromDate, setToDate, selectedDate } = useDateContext();
  const { setMeterId } = useMetersContext();
  const {
    recalculateCount,
    setRecalculateCount,
    clearSelectedRows,
    setOpenDrawer,
  } = useGridContext();

  const contextActions = (action: string, selectedRow?: any, grid?: any) => {
    switch (action) {
      case "versionHistory": {
        versionHistory();
        break;
      }
      case "measurementHistory": {
        measurementHistory(
          selectedRow ? selectedRow : grid?.selectedRows[0],
          grid
        );
        break;
      }
      case "showDetails": {
        showDetails();
        break;
      }
      case "recalculateRowTrigger": {
        recalculateRowTrigger();
        break;
      }
    }
  };

  const versionHistory = () => {
    setShowModal(true);
  };

  const measurementHistory = (selectedRow: any, grid?: any) => {
    clearSelectedRows(grid);
    setFromDate(selectedDate);
    setToDate(selectedDate);
    router.push(
      `/${instanceId}/${selectedRow?.meter_id
      }/hourly-measurement-history?fromDate=${convertToUtc(
        selectedDate
      ).toISOString()}&toDate=${convertToUtc(selectedDate).toISOString()}`
    );
    setMeterId(selectedRow?.meter_id);
  };

  const showDetails = () => {
    setOpenDrawer(true);
  };

  const recalculateRowTrigger = () => {
    setRecalculateCount(recalculateCount + 1);
  };

  return { contextActions };
}
