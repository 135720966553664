import { apiClient } from "api/APIUtils";

interface QuicksightResponse {
  EmbedUrl: string;
}

export const getQuicksightEmbedLinkApi = async (
  dashboardId: string
): Promise<QuicksightResponse> => {
  console.log(process.env.REACT_APP_QUICKSIGHT_USE_SESSION);

  if (process.env.REACT_APP_QUICKSIGHT_USE_SESSION === "true") {
    const response = await apiClient.get(`/quicksight/session/`);
    return { EmbedUrl: response.data.EmbedUrl } as QuicksightResponse;
  } else {
    const response = await apiClient.get(
      `/quicksight/dashboard/${dashboardId}`
    );
    return { EmbedUrl: response.data.EmbedUrl } as QuicksightResponse;
  }
};
