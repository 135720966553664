import React from "react";
import { getQuicksightEmbedLinkApi } from "api/QuicksightApi";
import { BranchStatus } from "components/common/Branch";

export interface QuicksightContextProps {
  getQuicksightEmbedLink: Function;
  quicksightEmbedLink: string;
  quicksightDashboardStatus: BranchStatus;
}

export const QuicksightContext = React.createContext<QuicksightContextProps>({
  getQuicksightEmbedLink: Function,
  quicksightEmbedLink: "",
  quicksightDashboardStatus: BranchStatus.Idle,
});

export const useQuicksightContext = () => React.useContext(QuicksightContext);

export const QuicksightProvider: React.FC = ({ children }) => {
  const [quicksightEmbedLink, setQuicksightEmbedLink] = React.useState<string>(
    ""
  );
  const [
    quicksightDashboardStatus,
    setQuicksightDashboardStatus,
  ] = React.useState<BranchStatus>(BranchStatus.Idle);

  const getQuicksightEmbedLink = async (dashboardId: string) => {
    try {
      setQuicksightDashboardStatus(BranchStatus.Loading);
      const response = await getQuicksightEmbedLinkApi(dashboardId);
      setQuicksightEmbedLink(response.EmbedUrl);
      setQuicksightDashboardStatus(BranchStatus.Finished);
    } catch (err) {
      console.error("Some error occurred fetching quicksight embed link", err);
      setQuicksightDashboardStatus(BranchStatus.Error);
    }
  };

  return (
    <QuicksightContext.Provider
      value={{
        getQuicksightEmbedLink,
        quicksightEmbedLink,
        quicksightDashboardStatus,
      }}
    >
      {children}
    </QuicksightContext.Provider>
  );
};
