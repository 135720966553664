import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import moment from "moment";

import { BranchStatus } from "components/common/Branch";
import { Calendar, CalendarFor } from "components/common/DatePicker/Calendar";
import { DatePickerButton } from "components/common/DatePicker/DatePickerButton";
import { useDataContext } from "contexts/DataContext";
import { useDateContext } from "contexts/DateContext";
import { useRouter } from "hooks/useRouter";

export const DatePicker: React.FC = () => {
  const {
    showDateRange,
    setSelectedDate,
    setFromDate,
    setToDate,
  } = useDateContext();
  const router = useRouter();
  const { listMeasurementsStatus } = useDataContext();

  const [showDatePicker, setShowDatePicker] = React.useState({
    show: false,
    for: "",
  });

  const splitDateAtOffset = (date: any) => {
    const str = date.toString();
    const tokens = str.split("-");
    const str1 = `${tokens[0]}`;
    const tokens2 = str1.split("+");
    const str2 = `${tokens2[0]}`;

    // Generates an offset * 2 allowing the GMT to be ignored (GMT-0400) and set the dates to the correct day.
    // let offset = 2*((date.getTimezoneOffset()/60)*10);

    // If str1 is not str2 it means str got split as positive time zone.
    if (str1 !== str2) {
      return new Date(str2);
    }
    // Else must be negative time zone so we need at add a day for wijmo.
    else {
      return moment(moment(date).add(1, "days"), moment.defaultFormat).toDate();
    }
  };

  useEffect(() => {
    // Split at date offset does some magic to account for timezones.
    if (router.query.date) {
      setSelectedDate(splitDateAtOffset(new Date(router.query.date)));
    }
    if (router.query.fromDate) {
      setFromDate(splitDateAtOffset(new Date(router.query.fromDate)));
    }
    if (router.query.toDate) {
      setToDate(splitDateAtOffset(new Date(router.query.toDate)));
    }

    // eslint-disable-next-line
  }, []);

  const openDateBox = (calendarFor: string) => {
    setShowDatePicker({ show: true, for: calendarFor });
  };

  const closeDateBox = () => {
    setShowDatePicker({ show: false, for: "" });
  };

  const isDisabled = listMeasurementsStatus === BranchStatus.Loading;

  const datePickerButtonJsx = (calendarFor: string) => {
    return (
      <DatePickerButton openDateBox={openDateBox} calendarFor={calendarFor} isDisabled={isDisabled} />
    );
  };

  return (
    <>
      {showDateRange ? (
        <>
          {datePickerButtonJsx(CalendarFor.From)}
          <Box as="span" mx={5}>
            {"To"}
          </Box>
          {datePickerButtonJsx(CalendarFor.To)}
        </>
      ) : (
        <>{datePickerButtonJsx(CalendarFor.Selected)}</>
      )}
      {showDatePicker.show && (
        <Calendar
          closeDateBox={closeDateBox}
          calendarFor={showDatePicker.for}
        />
      )}
    </>
  );
};
