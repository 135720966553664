import { createIcon } from "@chakra-ui/icon";

export const useIcons = () => {
  const IconMenuClose = createIcon({
    viewBox: "2 2 16 16",
    d:
      "M15.893 4.107c.302.303.323.78.064 1.106l-.064.073L11.179 10l4.714 4.714a.834.834 0 01-1.106 1.243l-.073-.064L10 11.179l-4.714 4.714a.834.834 0 01-1.243-1.106l.064-.073L8.821 10 4.107 5.286a.834.834 0 011.106-1.243l.073.064L10 8.821l4.714-4.714a.835.835 0 011.179 0z",
  });

  const IconMenuBars = createIcon({
    viewBox: "2 2 16 16",
    d:
      "M15.833 13.333a.834.834 0 01.098 1.661l-.098.006H4.167a.834.834 0 01-.098-1.661l.098-.006h11.666zm0-4.166a.834.834 0 01.098 1.661l-.098.005H4.167a.834.834 0 01-.098-1.661l.098-.005h11.666zm0-4.167a.834.834 0 01.098 1.661l-.098.006H4.167a.834.834 0 01-.098-1.661L4.167 5h11.666z",
  });

  const IconCog = createIcon({
    d:
      "M12.15 1.586a2.284 2.284 0 013.977 1.649l-.054 1.038a.623.623 0 00.654.651l1.038-.052a2.283 2.283 0 011.647 3.977l-.77.693a.617.617 0 00-.003.916l.774.695a2.284 2.284 0 01-1.65 3.977l-1.038-.054a.617.617 0 00-.651.651l.053 1.038a2.284 2.284 0 01-3.979 1.648l-.692-.77a.621.621 0 00-.918-.003l-.696.774a2.287 2.287 0 01-3.978-1.648l.053-1.04a.616.616 0 00-.653-.65l-1.044.054a2.286 2.286 0 01-1.632-3.978l.77-.692a.621.621 0 00.003-.916l-.774-.696a2.284 2.284 0 011.648-3.976l1.04.052a.617.617 0 00.651-.652l-.053-1.049a2.286 2.286 0 013.966-1.632l.697.767a.62.62 0 00.918.002zm1.84.931a.621.621 0 00-.603.185l-.696.774a2.288 2.288 0 01-3.389.002l-.691-.76a.616.616 0 00-.69-.15.612.612 0 00-.383.582l.053 1.035a2.287 2.287 0 01-2.403 2.404L4.15 6.536a.618.618 0 00-.448 1.074l.775.696a2.285 2.285 0 01-.002 3.391l-.77.693a.616.616 0 00.435 1.075l1.037-.054a2.288 2.288 0 012.404 2.402l-.052 1.038a.618.618 0 001.075.447l.697-.774a2.285 2.285 0 013.392.002l.693.77a.62.62 0 001.077-.444l-.054-1.037a2.288 2.288 0 012.404-2.404l1.037.054a.618.618 0 00.448-1.073l-.775-.696a2.288 2.288 0 01.002-3.392l.771-.693a.617.617 0 00-.446-1.074l-1.036.052a2.288 2.288 0 01-2.405-2.403l.054-1.037a.62.62 0 00-.38-.602zM9.333 7.113a3.334 3.334 0 110 5.774 3.337 3.337 0 010-5.774zm2.5 1.444a1.664 1.664 0 00-1.666 0l-.116.073a1.667 1.667 0 101.782-.073z",
  });

  const IconQuestion = createIcon({
    viewBox: "2 2 16 16",
    d:
      "M15.89 9.05a3.975 3.975 0 0 0-2.957-2.942C10.321 5.514 8.017 7.446 8 9.95l.005.147a.992.992 0 0 0 .982.904c.552 0 1-.447 1.002-.998a2.004 2.004 0 0 1 4.007-.002c0 1.102-.898 2-2.003 2H12a1 1 0 0 0-1 .987v2.014a1.001 1.001 0 0 0 2.004 0v-.782c0-.217.145-.399.35-.472A3.99 3.99 0 0 0 15.89 9.05",
  });

  const IconSample = createIcon({
    d:
      "M17.027 13.216c.333-.4.946-.4 1.28 0 .058.07.155.19.279.35l.205.27c.136.182.27.37.399.56.143.212.275.418.392.618.372.637.585 1.167.585 1.653a2.5 2.5 0 01-5 0c0-.486.212-1.016.583-1.652.118-.2.25-.407.394-.62.193-.284.398-.564.604-.83l.07-.09zm.64 1.908l-.144.207a9.45 9.45 0 00-.334.526c-.23.395-.356.707-.356.81a.833.833 0 001.667 0c0-.103-.125-.416-.357-.811a9.4 9.4 0 00-.333-.525l-.143-.207zM2.199 4.972C3.179 2.221 5.923.521 8.805.882c2.882.36 5.128 2.683 5.411 5.59.033.335.511.74 1.752 1.432l.774.433c.487.278.81.481 1.13.72.55.41.767 1.132.537 1.778a1.555 1.555 0 01-1.548 1.033c-.366-.02-.728-.067-1.214-.151l-1.087-.201c-1.366-.244-1.91-.236-2.14-.005a6.172 6.172 0 01-7.713.844C2.26 10.787 1.22 7.72 2.2 4.972zm6.4-2.437a4.537 4.537 0 00-4.83 2.997 4.603 4.603 0 001.838 5.42 4.507 4.507 0 005.633-.617l.087-.085c.76-.707 1.57-.728 3.495-.381l1.09.202c.187.033.35.059.5.079l.14.018-.097-.062a16.54 16.54 0 00-.732-.43l-.782-.437c-1.606-.914-2.277-1.524-2.383-2.604l-.023-.193c-.286-2.043-1.895-3.652-3.937-3.907z",
  });

  const IconHome = createIcon({
    d:
      "M16.834 10.417l.097.005c.424.05.736.411.736.828l-.006.097-.747 6.348a1.665 1.665 0 01-1.523 1.466l-.132.006H6.741c-.8 0-1.481-.568-1.635-1.342l-.02-.13-.747-6.348a.834.834 0 011.638-.29l.017.096.747 6.347h8.518l.747-6.347a.832.832 0 01.828-.736zm-4.477-9.08l.116.105 7.47 7.989a.834.834 0 01-1.142 1.21l-.076-.072-7.438-7.957a.436.436 0 00-.49-.07l-.052.038-7.47 7.99a.834.834 0 01-1.284-1.058l.067-.081 7.5-8.02c.764-.735 1.969-.768 2.8-.073z",
  });

  const IconOverflow = createIcon({
    viewBox: "0 0 14 14",
    d:
      "M2.917 5.833a1.167 1.167 0 11-.002 2.334 1.167 1.167 0 01.002-2.334zm4.083 0a1.168 1.168 0 11-.001 2.335A1.168 1.168 0 017 5.833zm4.083 0a1.167 1.167 0 110 2.334 1.167 1.167 0 010-2.334z",
  });

  const IconSearch = createIcon({
    viewBox: "0 0 14 14",
    d:
      "M1.385 2.255A4.404 4.404 0 118.35 7.643l2.693 2.694a.5.5 0 01-.66.748l-.047-.041L7.643 8.35a4.403 4.403 0 01-6.258-6.096zm3.931-.73a3.406 3.406 0 00-3.132 1.331 3.402 3.402 0 004.05 5.178 3.401 3.401 0 001.073-.72c0-.003.001-.004.002-.005a3.404 3.404 0 00-1.993-5.784z",
  });

  const IconControls = createIcon({
    viewBox: "0 0 12 12",
    d:
      "M4.5 6.5l.112.003A2.004 2.004 0 016.436 8H10.5a.5.5 0 01.058.997L10.5 9H6.437a2.001 2.001 0 01-3.874 0H1.5a.5.5 0 01-.058-.997L1.5 8l1.063-.001A2.001 2.001 0 014.5 6.5zm0 1l-.075.003A1 1 0 003.5 8.5c0-.004 0-.002 0 0a1.001 1.001 0 002 0 .997.997 0 00-1-1zm4.5-6A2 2 0 117.063 4H1.5a.5.5 0 01-.058-.997L1.5 3l5.563-.001A2.002 2.002 0 019 1.5zm0 1a1 1 0 00-1 1c0-.004 0-.002 0 0a1 1 0 101-1z",
  });

  const IconChevronDown = createIcon({
    viewBox: "0 0 12 12",
    d:
      "M2.646 4.146a.5.5 0 01.66-.041l.048.041L6 6.793l2.646-2.647a.5.5 0 01.66-.041l.048.041a.5.5 0 01.041.66l-.041.048-3 3a.5.5 0 01-.66.041l-.048-.041-3-3a.502.502 0 010-.708z",
  });

  const IconTick = createIcon({
    viewBox: "0 0 16 16",
    d:
      "M12.106 2.973a.665.665 0 011.16.653l-.039.068-5.754 8.951a1.003 1.003 0 01-1.54.175l-.07-.076-3.042-3.65a.668.668 0 01.967-.915l.057.06 2.752 3.303 5.509-8.57z",
  });

  const IconWarning = createIcon({
    viewBox: "0 0 16 16",
    d:
      "M8 1.333c.685 0 1.317.37 1.669.972l5.41 9.382c.337.609.34 1.354.005 1.965a1.941 1.941 0 01-1.68 1.015H2.589a1.938 1.938 0 01-1.672-1.015 2.048 2.048 0 01.01-1.974l5.407-9.376c.35-.6.982-.969 1.667-.969zm0 1.334a.6.6 0 00-.514.304l-5.399 9.364a.706.706 0 00-.002.675.6.6 0 00.51.323h10.802a.603.603 0 00.518-.323.702.702 0 00.003-.666l-5.403-9.37A.599.599 0 008 2.667zm0 8A.667.667 0 118 12a.667.667 0 010-1.333zm0-5.334c.342 0 .624.258.662.59L8.667 6v2.667a.667.667 0 01-1.329.077l-.005-.077V6c0-.368.299-.667.667-.667z",
  });
  const IconArrowRight = createIcon({
    viewBox: "0 0 24 24",
    d:
      "M13.613 5.21l.094.083 6 6a.97.97 0 01.08.09l-.08-.09a.927.927 0 01.147.186l.021.037c.011.02.022.04.031.06l.023.053.021.06.014.045.016.065.009.053.007.06.003.056L20 12v.033l-.004.052L20 12c0 .05-.004.1-.011.149l-.01.052a.762.762 0 01-.015.065l-.014.046-.021.06-.023.051-.03.061-.022.037a1.2 1.2 0 01-.05.074l-.014.018c-.005.007-.007.01-.01.012l-.073.082-6 6a1 1 0 01-1.497-1.32l.083-.094L16.584 13H5a1 1 0 01-.117-1.993L5 11h11.586l-4.293-4.293a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083z",
  });
  const IconArrowLeft = createIcon({
    viewBox: "0 0 24 24",
    d:
      "M10.387 5.21l-.094.083-6 6a.927.927 0 00-.097.112l-.071.11-.054.114-.035.105-.03.148L4 12l.003.075.017.126.03.111.044.111.052.098.074.104.073.082 6 6a1 1 0 001.497-1.32l-.083-.094L7.416 13H19a1 1 0 00.117-1.993L19 11H7.414l4.293-4.293a1 1 0 00.083-1.32l-.083-.094a1 1 0 00-1.32-.083z",
  });

  return {
    IconMenuBars,
    IconMenuClose,
    IconCog,
    IconSample,
    IconQuestion,
    IconHome,
    IconOverflow,
    IconSearch,
    IconControls,
    IconChevronDown,
    IconTick,
    IconWarning,
    IconArrowRight,
    IconArrowLeft,
  };
};
