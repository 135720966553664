import React from "react";
import { useQuicksightContext } from "contexts/QuicksightContext";
import { useAppContext } from "contexts/AppContext";
import { PageLayout } from "components/layout/PageLayout/PageLayout";
import { Dashboard } from "components/dashboard/Dashboard";
import { Branch, BranchStatus } from "components/common/Branch";
import { Error } from "components/common/Error";
import { Skeleton } from "components/common/Skeleton";
import { useTranslation } from "react-i18next";

export const QuicksightDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    getQuicksightEmbedLink,
    quicksightDashboardStatus,
    quicksightEmbedLink,
  } = useQuicksightContext();
  const { dashboardId } = useAppContext();

  const fetchQuicksightLink = React.useCallback(async () => {
    getQuicksightEmbedLink(dashboardId);
  }, [getQuicksightEmbedLink, dashboardId]);

  React.useEffect(() => {
    fetchQuicksightLink();
    // eslint-disable-next-line
  }, []);

  return (
    <PageLayout title={t("pages.dashboard.title")}>
      <Branch
        isLoading={quicksightDashboardStatus === BranchStatus.Loading}
        LoadingComponent={() => <Skeleton header rows={8} cols={6} />}
        hasError={quicksightDashboardStatus === BranchStatus.Error}
        ErrorComponent={() => <Error retry={fetchQuicksightLink} />}
        Component={() => <Dashboard url={quicksightEmbedLink} />}
      />
    </PageLayout>
  );
};
