import React from "react";
import { Global } from "@emotion/react";

export const GlobalStyle = () => (
  <Global
    styles={(theme: any) => `
      html,
      body {
        font-family: "Inter", sans-serif; 
        background-color: ${theme.colors?.ui?.[0] || "#fff"};
      }

      /* hack to fix vertical misalignment of button loading animation */
      .chakra-button__spinner {
        margin-top: 2px;
      }

      /* force toast above Wijmo frozen cells */
      #chakra-toast-manager-bottom-left {
        z-index: 90001;
      }

      /* Wijmo right click context menu */
      .wj-listbox-item {
        font-size: 14px;
      }
      div#_dropdown {
        padding: 2px 10px;
      }
      .my-tip {
        font-family: Inter;
        font-size: 12px;
      }

      select {
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
      }
      select::-ms-expand {
        display: none!important;
      }
    `}
  />
);
