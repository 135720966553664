import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { Heading } from "components/common/Heading";
import { Button } from "components/common/Button";
import { Text } from "components/common/Text";

interface ConfirmationDialogProps {
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  description,
  confirmText,
  cancelText,
  isOpen,
  onClose,
  onConfirm,
  ...props
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const cancelRef = useRef<any>(null);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
    onClose();
    setLoading(false);
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered={true}
      {...props}
    >
      <AlertDialogOverlay
        data-testid="confirmation-dialog-overlay"
        bgColor={`rgba(30, 35, 45, 0.64)`}
        zIndex="1500"
      >
        <AlertDialogContent
          px={1}
          py={2}
          borderRadius={6}
          data-testid="confirmation-dialog-content"
        >
          <AlertDialogHeader>
            <Heading
              data-testid="confirmation-dialog-title"
              size="xxs"
              fontWeight="500"
            >
              {title}
            </Heading>
          </AlertDialogHeader>

          {description && (
            <AlertDialogBody py={0}>
              <Text data-testid="confirmation-dialog-description" fontSize="md">
                {description}
              </Text>
            </AlertDialogBody>
          )}

          <AlertDialogFooter>
            <Button
              testId="confirmation-dialog-cancel"
              variant="outline"
              ref={cancelRef}
              onClick={onClose}
              mr={4}
              isDisabled={loading}
              size="sm"
            >
              {cancelText || t("components.confirmationDialog.genericCancel")}
            </Button>
            <Button
              testId="confirmation-dialog-confirm"
              onClick={handleConfirm}
              isLoading={loading}
              size="sm"
            >
              {confirmText || t("components.confirmationDialog.genericConfirm")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
