import { apiClient } from "api/APIUtils";
import { MeterConfig } from "models/MeterConfig";
import buildQuery from "odata-query";

interface MetersResponse {
  values: MeterConfig[];
  nextLink: string;
}

export const listMeterConfigsAPI = async (
  instanceId: string,
  id: string,
  date: Date
): Promise<MeterConfig[]> => {
  const query = buildQuery({ filter: { contract_day: date } });
  const response = await apiClient.get(
    `/${instanceId}/meters/${id}/configs${query}`
  );
  return response.data.value;
};

export const listMetersAPI = async (
  instanceId: string,
  date: Date,
  meterId?: string
): Promise<MetersResponse> => {
  const query = buildQuery({ filter: { contract_day: date } });
  const response = await apiClient.get(
    `/${instanceId}/meters${meterId && "/" + meterId}${query}`
  );

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MetersResponse;
};

export const listMoreMetersAPI = async (
  loadMoreMetersLink: string
): Promise<MetersResponse> => {
  const response = await apiClient.get(loadMoreMetersLink);

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MetersResponse;
};
