import React from "react";
import { CalendarFor } from "components/common/DatePicker/Calendar";
import { BranchStatus } from "components/common/Branch";
import { useDataContext } from "contexts/DataContext";
import { useDateContext } from "contexts/DateContext";
import { Button } from "components/common/Button";
import moment from "moment";
import { useRouter } from "hooks/useRouter";
import { convertToUtc } from "utils/Utils";

interface Props {
  openDateBox: Function;
  calendarFor?: string;
  isDisabled?: boolean;
}

export const DatePickerButton: React.FC<Props> = ({
  openDateBox,
  calendarFor,
  isDisabled = false
}) => {
  const { listMeasurementsStatus } = useDataContext();
  const {
    showDateRange,
    setSelectedDate,
    selectedDate,
    fromDate,
    toDate,
  } = useDateContext();
  const router = useRouter();

  const handleClick = () => {
    switch (calendarFor) {
      case CalendarFor.From: {
        openDateBox(CalendarFor.From);
        break;
      }
      case CalendarFor.To: {
        openDateBox(CalendarFor.To);
        break;
      }
      default: {
        openDateBox(CalendarFor.Selected);
      }
    }
  };

  const addDay = () => {
    const date = moment(
      moment(selectedDate).add(1, "days"),
      moment.defaultFormat
    ).toDate();
    setSelectedDate(date);
    router.push(`${router.pathname}?date=${convertToUtc(date).toISOString()}`);
  };

  const subtractDay = () => {
    const date = moment(
      moment(selectedDate).subtract(1, "days"),
      moment.defaultFormat
    ).toDate();
    setSelectedDate(date);
    router.push(`${router.pathname}?date=${convertToUtc(date).toISOString()}`);
  };

  const getDate = () => {
    switch (calendarFor) {
      case CalendarFor.From: {
        return fromDate;
      }
      case CalendarFor.To: {
        return toDate;
      }
      default: {
        return selectedDate;
      }
    }
  };

  const formatDateForUi = (date: Date) => {
    return moment(date).format("D/MMM/YYYY");
  };

  const previousNextButton = (
    onClick: () => void,
    icon: string,
    disabled = false
  ) => (
    <Button
      testId={`button-test-id-${icon}`}
      onClick={onClick}
      variant="ghost"
      size="xs"
      padding="7px"
      minWidth={"0.4rem"}
      disabled={(listMeasurementsStatus === BranchStatus.Loading) || disabled || isDisabled}

    >
      <span className={icon}></span>
    </Button>
  );

  return (
    <>
      {showDateRange ? (
        <Button onClick={handleClick} variant="gradient" size="sm" disabled={isDisabled}>
          {formatDateForUi(getDate())}
        </Button>
      ) : (
        <>
          {previousNextButton(subtractDay, "wj-glyph-left")}
          <Button onClick={handleClick} variant="ghost" size="sm" disabled={isDisabled}>
            {formatDateForUi(getDate())}
          </Button>
          {previousNextButton(
            addDay,
            "wj-glyph-right",
            moment(getDate()).isSame(new Date(Date.now()), "day")
          )}
        </>
      )}
    </>
  );
};
