import React from "react";
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { Text } from "components/common/Text";
import { NavButton } from "components/layout/NavButton";
import Logo from "images/energysys-logo.png";
import { NavItem } from "components/layout/NavItem";
import { useTranslation } from "react-i18next";
import { useAppContext } from "contexts/AppContext";
import packageJson from "../../../../package.json";
import { getBuildDate } from "utils/Utils";

export const Nav: React.FC = () => {
  const { t } = useTranslation();
  const { instanceId } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NavButton isOpen={true} onClick={onOpen} aria-label="Open Main Menu" />
      <Drawer isOpen={isOpen} placement="left" size="xs" onClose={onClose}>
        <DrawerOverlay backgroundColor="rgba(0,0,0,0.63)" zIndex="1000" />
        <DrawerContent zIndex="1001">
          <DrawerHeader>
            <NavButton
              isOpen={false}
              onClick={onClose}
              aria-label="Close Main Menu"
            />
            <Box my={8}>
              <Image src={Logo} height="40px" />
            </Box>
          </DrawerHeader>
          <DrawerBody p={0}>
            <NavItem to={"/dashboard"}>{t("components.nav.dashboard")}</NavItem>
            <NavItem to={`/${instanceId}/daily-measurements`}>
              {t("components.nav.dailyMeasurements")}
            </NavItem>
            {/* <NavItem to={"/meters"}>{t("components.nav.meters")}</NavItem> */}
            {/* {process.env.REACT_APP_INCLUDE_AUDIT_LOGGING === "true" && (
              <NavItem to={"/audit-logging"}>
                {t("components.nav.auditLogging")}
              </NavItem>
            )} */}
          </DrawerBody>
          <DrawerFooter alignItems="flex-start" flexDirection="column">
            <Text fontSize={"xs"}>
              Build date: {getBuildDate(packageJson.buildDate)}
            </Text>
            <Text fontSize={"xs"}>
              <span role="img" aria-label="Copyright">
                ©️
              </span>
              2007-{new Date().getFullYear()} EnergySys Limited
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
