import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Heading } from "components/common/Heading";
import { useIcons } from "hooks/useIcons";

interface ModalProps {
  title: string;
  size?: string;
}

export const Modal: React.FC<ModalProps & ChakraModalProps> = ({
  title,
  children,
  ...props
}) => {
  const { IconMenuClose } = useIcons();

  return (
    <ChakraModal {...props} isCentered={true}>
      <ModalOverlay
        data-testid="modal-overlay"
        bgColor="rgba(30, 35, 45, 0.64)"
        zIndex="4"
      >
        <ModalContent
          p={0}
          position="relative"
          data-testid="modal-content"
          maxHeight="800px"
          minHeight="350px"
          width="98%"
        >
          <ModalHeader
            py={2}
            px={4}
            bg="brand.1000"
            borderRadius="3px 3px 0 0"
            mb={5}
          >
            <Heading
              data-testid="modal-title"
              size="xxs"
              fontWeight="500"
              color="ui.0"
            >
              {title}
            </Heading>
          </ModalHeader>
          <ModalCloseButton
            borderRadius="100%"
            right="0.5rem"
            top="0.4rem"
            padding=".5rem"
            color="ui.0"
            data-testid="modal-close-button"
          >
            <IconMenuClose />
          </ModalCloseButton>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  );
};
