import React from "react";
import { Text, Flex, Spinner } from "@chakra-ui/react";

interface Props {
  size?: string;
  text?: string;
  bg?: string;
  w?: string;
}

export const Loading: React.FC<Props> = ({ children, size, text, bg, w }) => (
  <Flex
    width={w ? w : "100vw"}
    height="100%"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    bg={bg ? bg : ""}
  >
    <Spinner
      thickness="3px"
      speed="0.65s"
      emptyColor="gray.200"
      color="brand.800"
      size={size ? size : "xl"}
    />
    {text && <Text>{text}</Text>}
    {children}
  </Flex>
);
