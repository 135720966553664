import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import { useAuth } from "hooks/useAuth";
import * as Yup from "yup";
import { Box, FormControl, Button } from "@chakra-ui/react";
import { InputText } from "components/common/InputText";
import { useToasts } from "contexts/ToastContext";

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { setToast } = useToasts();

  return (
    <Formik
      initialValues={{ username: "" }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required(t("common.error.form.required")),
      })}
      onSubmit={async ({ username }) => {
        try {
          await login({ email: username });
        } catch (e) {
          setToast({
            status: "error",
            title: t("common.error.genericTitle"),
            message: t("common.error.genericMessage"),
          });
        }
      }}
      validateOnChange={false}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="username">
            {({ field, form: { errors, touched } }: any) => (
              <FormControl
                py="8"
                isInvalid={errors.username && touched.username}
              >
                <InputText
                  id="username"
                  {...field}
                  label={t("components.login.enterUsername")}
                  validationError={errors.username}
                />
              </FormControl>
            )}
          </Field>
          <Box textAlign="right">
            <Button
              variant="solid"
              px="3"
              height="32px"
              isLoading={isSubmitting}
              type="submit"
              backgroundColor="brand.1000"
              _hover={{ bg: "brand.1200" }}
              color="ui.0"
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
