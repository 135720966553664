import React from "react";
import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { useIcons } from "hooks/useIcons";

type Props = {
  isOpen?: boolean;
};

export const NavButton: React.FC<Props & IconButtonProps> = ({
  isOpen = true,
  ...props
}) => {
  const { IconMenuBars, IconMenuClose } = useIcons();

  return (
    <IconButton
      {...props}
      data-testid="testid-button"
      backgroundColor="ui.100"
      isRound
      icon={
        isOpen ? (
          <IconMenuBars data-testid="testid-icon-bars" />
        ) : (
          <IconMenuClose data-testid="testid-icon-close" />
        )
      }
    />
  );
};
