import { useRouter } from "hooks/useRouter";

export function useContextMenuItems() {
  const router = useRouter();

  let items = [];
  router.pathname.includes("daily-measurements") &&
    items.push(
      { header: "Measurement History", cmd: "measurementHistory" },
      { header: "Show Details", cmd: "showDetails" }
    );
  router.pathname.includes("hourly-measurement-history") &&
    items.push({ header: "Recalculate Row", cmd: "recalculateRowTrigger" });
  router.pathname.includes("measurement") &&
    items.push({ header: "Version History", cmd: "versionHistory" });

  return items;
}
