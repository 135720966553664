import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "@chakra-ui/react";

import { AgGridReact } from "ag-grid-react";
import { CellValueChangedEvent, GridReadyEvent, RangeSelectionChangedEvent, BodyScrollEvent, GetRowIdParams, GridApi } from "ag-grid-community";
import { ColDef, RowClassParams } from "ag-grid-enterprise";
import "ag-grid-enterprise";

import NavigationPrompt from "react-router-navigation-prompt";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import remove from "lodash/remove";
import union from "lodash/union";

import { useContextActions } from "hooks/useContextActions";
import { useRouter } from "hooks/useRouter";

import { useAppContext } from "contexts/AppContext";
import { useDataContext } from "contexts/DataContext";
import { useErrorLoggingContext } from "contexts/ErrorLoggingContext";
import { useGridContext } from "contexts/GridContext";
import { useToasts } from "contexts/ToastContext";

import { AuditComment } from "models/AuditComment";
import { ErrorLogging } from "models/ErrorLogging";
import { Measurement, MeasurementPayload } from "models/Measurement";

import { ConfirmationDialog } from "components/common/ConfirmationDialog";
import { ConfirmEditDialog } from "components/common/ConfirmEditDialog";
import { Loading } from "components/common/Loading";

import "./EMSGrid.scss";

type EMSGridColumn = {
  headerText: string;
  unit?: string;
  binding: string;
};

type EMSGridProps = {
  data: any[];
  preferences: any;
  fetchMoreData: (url: string) => void;
  fetchData: () => void;
  autoGenerateColumns?: boolean;
  entityType?: any;
  endpoint?: string;
  maxHeight?: string;
  minHeight?: string;
  height?: string;
  loadMoreLink?: string;
  disableContextMenu?: boolean;
};

type NumberFormatterProps = {
  value: number;
  format: string | undefined;
};

const DataTypes = {
  "STRING": "String",
  "NUMBER": "Number",
  "DATE": "Date",
  "BOOLEAN": "Boolean"
};

const SKregex = /([a-zA-Z][0-9]{1,})#([a-zA-Z]a*)\w+#([0-9]{4}-[0-9]{2}-[0-9]{2})T([0-9]{2}:[0-9]{2}:[0-9]{2})(-([0-9]{2}:[0-9]{2}))?/;

const numberFormatRegEx = new RegExp(/^n(\d+)/);

const numberFormatter = ({ value, format }: NumberFormatterProps) => {
  let fixed = 2;

  if (format !== undefined && numberFormatRegEx.test(format)) {
    fixed = parseInt(format.match(/^n(\d+)/)?.[1] || "2");
  }

  return new Intl.NumberFormat(
    "en-GB",
    { minimumFractionDigits: fixed, maximumFractionDigits: fixed }
  ).format(value);
};

const isNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

const EMSGrid = ({
  data,
  preferences,
  fetchData,
  fetchMoreData,
  autoGenerateColumns = false,
  entityType,
  endpoint,
  maxHeight,
  minHeight,
  height,
  loadMoreLink,
  disableContextMenu = false,
}: EMSGridProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { contextActions } = useContextActions();
  const { user, userPermissions } = useAppContext();
  const { selectedRows, setSelectedRows, recalculateCount, setRecalculateCount } = useGridContext();
  const { updateMeasurements, recalculateMeasurements } = useDataContext();

  const { logError } = useErrorLoggingContext();
  const { setToast } = useToasts();
  const gridRef = useRef<AgGridReact>(null);

  const [originalData, setOriginalData] = useState<any[]>(cloneDeep(data));
  const [gridData, setGridData] = useState<any[]>(cloneDeep(data));

  const [columns, setColumns] = useState<any[]>([]);
  const [gridContainerStyles, setGridContainerStyles] = useState<any>({});
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
  const [isRecalculating, setIsRecalculating] = useState<boolean>(false);
  const [editedRowIds, setEditedRowIds] = useState<string[]>([]);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showShowCancelEditDialog, setShowCancelEditDialog] = useState<boolean>(false);

  const CancelEditDialog: FC<{
    onConfirm: () => void;
    onCancel: () => void;
  }> = ({ onConfirm, onCancel }) => (
    <ConfirmationDialog
      isOpen={true}
      title={t("components.saveChangesDialog.title")}
      description={t("components.saveChangesDialog.message")}
      confirmText={t("components.saveChangesDialog.confirmLabel")}
      cancelText={t("components.saveChangesDialog.cancelLabel")}
      onConfirm={onConfirm}
      onClose={onCancel}
    />
  );

  const onGridReady = (params: GridReadyEvent) => {
    const calculatedStyles: any = {};

    setGridApi(params.api);

    if (height) calculatedStyles["height"] = height;
    if (minHeight) calculatedStyles["minHeight"] = minHeight;
    if (maxHeight) calculatedStyles["maxHeight"] = maxHeight;
    setGridContainerStyles(calculatedStyles);
  };

  /**
   * Handles when a cell value has changed
   * 
   * @param params AG Grid Cell value change event
   * @returns void
   */
  const onCellValueChanged = (params: CellValueChangedEvent) => {
    const rowNode = params.node;
    const rowId = rowNode.id;
    const rowIndex = params.rowIndex;
    const colId = params.column.getColId();
    const originalField = rowIndex !== null ? get(originalData[rowIndex], colId) : null;
    const hasValue = originalField?.hasOwnProperty("value");
    const originalValue = hasValue ? originalField.value : originalField;

    // If we don't have a node ID exit early
    if (!rowId) return;

    // Set an empty array if it doesn't exist
    // This is a custom property we set to allow conditional classes to be applied
    if (!params.data.editedColumns) params.data.editedColumns = [];

    // Add or remove the column ID from edited columns
    if (originalValue !== params.newValue) {
      params.data.editedColumns = union(params.data.editedColumns, [colId]);
    } else {
      remove(params.data.editedColumns, (column) => column === colId);
    }

    if (params.data.editedColumns.length > 0) {
      // Add to edited row IDs
      setEditedRowIds((rowIds: string[]) => union(rowIds, [rowId]));
    } else {
      // Remove from edited row IDs as this row has no edited columns
      setEditedRowIds((rowIds: string[]) => {
        const newRowIds = union([], rowIds); // We need a new array to ensure change detection
        remove(newRowIds, (id) => id === rowId);
        return newRowIds;
      });
    }

    // Force cell class recalculation
    params.api.refreshCells();
  };

  // Provide row specific styling based on status
  const getRowClass = (params: RowClassParams) => {
    const status = params.data.status;
    let rowClass = "";

    switch (status) {
      case "BAD":
        rowClass = "bad-record";
        break;
      case "MANUAL":
        rowClass = "manual-record";
        break;
      case "RECALCULATED":
        rowClass = "recalculated-record";
        break;
      default:
        rowClass = "ok-record";
    }

    return rowClass;
  };

  const onSelectionChange = (event: RangeSelectionChangedEvent) => {
    const api = event.api;
    const currentRowRange = api.getCellRanges();

    // Deselect previous selections
    api.deselectAll();

    if (currentRowRange && currentRowRange !== undefined && currentRowRange.length > 0) {
      const startRow = currentRowRange[0]?.startRow?.rowIndex;
      const endRow = currentRowRange[0]?.endRow?.rowIndex;
      const selectedColumns = currentRowRange[0]?.columns;
      const isRowSelector = selectedColumns.length === 1 && selectedColumns[0].getColId() === "0";
      let currentSelectedRows = [];

      if (startRow !== undefined && endRow !== undefined) {
        if (startRow <= endRow) {
          currentSelectedRows = gridData.slice(startRow, endRow + 1);
        } else {
          currentSelectedRows = gridData.slice(endRow, startRow + 1).reverse();
        }

        // Selecting entire rows manually
        if (isRowSelector) {
          if (startRow <= endRow) {
            for (let i = startRow; i <= endRow; i += 1) {
              const rowNode = api?.getDisplayedRowAtIndex(i);
              rowNode?.setSelected(true);
            }
          } else {
            for (let i = startRow; i >= endRow; i -= 1) {
              const rowNode = api?.getDisplayedRowAtIndex(i);
              rowNode?.setSelected(true);
            }
          }
        }

        // Update our own grid selected rows state for performing actions
        setSelectedRows(currentSelectedRows);
      }
    }
  };

  const loadMore = async (url: string) => {
    setIsLoadingNext(true);
    await fetchMoreData(url);
    setIsLoadingNext(false);
  };

  const onBodyScroll = (event: BodyScrollEvent) => {
    if (!loadMoreLink || isLoadingNext) return null;

    const api = event.api;
    const bottomPosition = api.getVerticalPixelRange().bottom;
    const gridHeight = api.getDisplayedRowCount() * api.getSizesForCurrentTheme().rowHeight;

    if (gridHeight - bottomPosition <= 1200) {
      loadMore(loadMoreLink);
    }

  };

  const getRowId = useCallback((params: GetRowIdParams) => `${params.data.PK}-${params.data.SK}`, []);

  const getContextMenuItems = useCallback((params) => {
    const api = params.api;
    const selectedRow = selectedRows.length > 0 ? selectedRows[0] : params.node.data;
    const contextMenu = [];
    const rowIndex = params.node.rowIndex;
    const columns = [params.column.colId];
    const cellRange = {
      rowStartIndex: rowIndex,
      rowEndIndex: rowIndex,
      columns
    }

    api.clearRangeSelection();
    api.clearFocusedCell();
    api.addCellRange(cellRange); // Focus and select this single cell

    if (router.pathname.includes("daily-measurements")) {
      contextMenu.push({
        name: "Measurement History",
        action: () => {
          contextActions("measurementHistory", selectedRow);
        }
      }, {
        name: "Show Details",
        action: function () {
          contextActions("showDetails");
        }
      });
    }

    if (router.pathname.includes("hourly-measurement-history")) {
      contextMenu.push({
        name: "Recalculate Row",
        action: function () {
          contextActions("recalculateRowTrigger");
        }
      });
    }

    if (router.pathname.includes("measurement")) {
      contextMenu.push({
        name: "Version History",
        action: function () {
          contextActions("versionHistory");
        }
      });
    }

    return contextMenu;
  }, [contextActions, router.pathname, selectedRows]);

  const onCancelChanges = () => {
    setEditedRowIds([]);
    fetchData();
    setShowEditDialog(false);
  };

  const getEditedRows = () => {
    const editedRows: Measurement[] = [];

    if (!gridApi) return [];

    editedRowIds.forEach(rowId => {
      const editedData = gridApi.getRowNode(rowId)?.data;

      if (editedData) editedRows.push(editedData);
    });

    return editedRows;
  };

  /** Building some of the payload for saving whilst checking SK and timestamp are in correct format before continuing. */
  const onSaveChanges = async (comment: string) => {
    const editedRows = getEditedRows();
    try {
      editedRows.forEach((row) => {
        if (!row || !SKregex.test(row.SK as string)) {
          throw new Error("Invalid SK or Timestamp");
        }
      });

      await updateMeasurements(
        {
          audit: {
            change_user: user,
            comment,
            created_timestamp: new Date().toISOString(),
          } as AuditComment,
          requests: editedRows.map((measurement) => {
            return {
              method: "POST", // TODO: change to PUT when the API supports it.
              endpoint,
              measurement,
            };
          }),
        } as MeasurementPayload,
        () => {
          fetchData();
          setShowEditDialog(false);
        }
      );
    } catch (err) {
      setToast({
        status: "error",
        title: `${err}.`,
        message: t("common.error.genericMessage"),
      });
      logError({
        logType: "Error",
        logSeverity: "Medium",
        logContent: `User ${user} got exception from grid onSaveChanges method: ${err}`,
      } as ErrorLogging);
    }
  };

  useEffect(() => {
    const updateData = (gridData: any[], updatedRows: any[]) => {
      const counters = {
        success: 0,
        fail: 0
      }

      updatedRows.forEach(newRow => {
        const index = gridData.findIndex((row: any) => (row.PK === newRow.PK) && (row.SK === newRow.SK));

        if (index > -1) {
          const rowId = `${newRow.PK}-${newRow.SK}`;

          gridData[index] = newRow;
          setEditedRowIds((rowIds: string[]) => union(rowIds, [rowId]));
          counters.success++;
        } else {
          counters.fail++
        }
      });

      if (counters.success > 0) {
        setShowEditDialog(true);

        setToast({
          status: "success",
          title: t("components.kcclCalculation.successCount", { success: counters.success, total: counters.success + counters.fail }),
        });
      }
      if (counters.fail > 0) {
        setToast({
          status: "warning",
          title: t("components.kcclCalculation.failCount", { fail: counters.fail, total: counters.success + counters.fail }),
        });
      }
      return gridData;
    };

    const recalculateRow = async () => {
      setIsRecalculating(true);

      const updatedRows = await recalculateMeasurements({
        measurements: selectedRows,
      });

      if (updatedRows?.length) {
        updatedRows.map((row: any) => {
          row.status = "RECALCULATED";
          return row;
        });

        setGridData(updateData([...gridData], updatedRows));
      }

      setIsRecalculating(false);
    };

    if (recalculateCount > 0) {
      recalculateRow();
      setRecalculateCount(0);
    }
  }, [recalculateCount, setRecalculateCount, gridData, recalculateMeasurements, selectedRows, setToast, t]);

  useEffect(() => {
    if (editedRowIds.length > 0) {
      setShowEditDialog(true);
    } else {
      setShowEditDialog(false);
    }
  }, [editedRowIds]);

  useEffect(() => {
    /**
     * We rely on the entityType to be passed in to the grid as we were
     * already setting the entity type name
     */
    const getDataType = (binding: any) => {
      const dataTypeObject = entityType.children.find(
        (x: any) => x.attributes.Name === binding
      );
      const dataTypeFromMetaData =
        dataTypeObject && dataTypeObject.attributes.Type;

      let dataType = DataTypes.NUMBER;

      if (dataTypeFromMetaData) {
        Object.values(DataTypes).forEach((typeVal) => {
          if (dataTypeFromMetaData.includes(typeVal)) dataType = typeVal;
        });
      }

      return dataType;
    };

    const makeColumns = (forceReadOnly: boolean) => {
      const cols: any[] = [{
        pinned: "left",
        menuTabs: [],
        width: 20,
        editable: false,
        resizable: false,
        cellClass: "selection-col no-border",
        suppressNavigable: true
      }];

      // recursive method to create columns from nested data
      const column = (col: any, bind?: string | undefined): void => {
        const { label, read_only, hidden, pinned, format, min_width } = col[1];
        const binding: string = bind ? `${bind}.${col[0]}` : col[0];
        const dataType = entityType ? getDataType(binding) : null;
        const columnDef: ColDef = {
          menuTabs: [],
          headerName: label,
          initialWidth: 100,
          editable: !forceReadOnly && !read_only,
          resizable: true,
          singleClickEdit: false,
          sortable: true,
          hide: hidden || false,
          headerClass: (forceReadOnly || read_only) ? "readonly" : "",
          cellClassRules: {
            'edited': params => params.data.editedColumns?.length && params.data.editedColumns.includes(binding)
          }
        };

        // Initial column status
        if (pinned) {
          columnDef.pinned = "left";
          columnDef.width = 120;
        }

        // Set min width if provided
        if (min_width) columnDef.minWidth = min_width;

        // If no provided label, and column is not hidden, use the column name (binding)
        if (!label) {
          if (hidden) {
            return void 0;
          } else {
            Object.entries(col[1]).forEach((c: any) => column(c, binding));
          }
          // If we have a label and colum is not hidden, generate the column definition  
        } else if (!hidden) {
          const measurement = binding
            .split(".")
            .reduce((a, b) => a[b] as any, data[0]);
          const hasValue = measurement?.hasOwnProperty("value");
          const unit = measurement?.unit;

          if (unit) columnDef.headerName += ` (${unit})`;
          if (hasValue) columnDef.field += ".value";
          columnDef.minWidth = min_width || 100;

          switch (dataType) {
            case DataTypes.NUMBER:
              columnDef.field = binding;
              columnDef.valueGetter = (params) => {
                const boundVal = get(params.data, binding);
                try {
                  const val = hasValue ? boundVal.value : boundVal;
                  if (isNullOrUndefined(val)) return "";
                  return val;
                } catch (err) {
                  logError({
                    logType: "Error",
                    logSeverity: "Medium",
                    logContent: `User ${user} got exception at binding ${binding}: ${err}`,
                  } as ErrorLogging);
                  return "";
                }
              };
              columnDef.valueSetter = (params) => {
                const newVal = params.newValue;
                const oldVal = params.oldValue;
                const parseFloatVal = parseFloat(newVal);
                let boundVal = get(params.data, binding);
                let val;

                if (newVal === "") {
                  // We need to set empty strings to null
                  val = null;
                } else if (isNaN(parseFloatVal)) {
                  // If the parseFloat value is NaN, we revert to the old value
                  val = oldVal;
                } else {
                  // Otherwise we can use the parseFloat value
                  val = parseFloatVal;
                }

                if (hasValue) {
                  boundVal.value = val;
                } else {
                  boundVal = val;
                }
                return true;
              };
              columnDef.type = "numericColumn";
              columnDef.valueFormatter = params => {
                if (params.value === null || params.value === undefined || params.value === "") return params.value;

                return numberFormatter({ value: parseFloat(params.value), format });
              };
              break;
            case DataTypes.DATE:
              columnDef.field = binding;
              columnDef.valueFormatter = params => {
                const offset = moment.parseZone(params.value).utcOffset();
                const momentUTCDate: any = moment.utc(params.value);
                const dateFormat = format || "DD/MMM/YYYY HH:mm:ss";

                return momentUTCDate.utcOffset(offset).format(dateFormat);
              }
              break;
            case DataTypes.STRING:
              columnDef.tooltipField = binding;
              columnDef.field = binding;
              break;
            case DataTypes.BOOLEAN:
            default:
              columnDef.field = binding;
              break;
          }

          cols.push(columnDef);
        }
      };

      Object.entries(preferences).forEach((c: any) => column(c));

      setColumns(cols);

      if (gridApi) {
        gridApi!.sizeColumnsToFit();
      }
    };

    if (entityType && preferences && data.length) {
      // If the grid should be read only, or the user permission is readonly, then we force readonly on the whole data grid.
      const forceReadOnly = !!preferences["read_only"] || userPermissions === "ReadOnly";

      setIsReadOnly(forceReadOnly);

      // build the columns if we don't have columns already built
      if (columns.length === 0) makeColumns(forceReadOnly);

      // Update original data with a clone of new data
      setOriginalData(cloneDeep(data));

      // Update our gridData state
      setGridData(data);
    } else {
      setIsReadOnly(true);
    };
  }, [data, preferences, entityType, columns.length, userPermissions, gridApi]);

  return (
    <>
      {!isReadOnly && editedRowIds.length > 0 && (
        <NavigationPrompt when={true}>
          {({ onConfirm, onCancel }) => (
            <CancelEditDialog onConfirm={onConfirm} onCancel={onCancel} />
          )}
        </NavigationPrompt>
      )}

      {showShowCancelEditDialog && (
        <CancelEditDialog
          onConfirm={onCancelChanges}
          onCancel={() => setShowCancelEditDialog(false)}
        />
      )}

      <Flex
        flexDirection="column"
        width="100%"
        border="1px solid"
        borderColor="ui.400"
        marginTop="-1px"
        pos="relative"
      >
        {showEditDialog && (
          <ConfirmEditDialog
            onClose={() => setShowCancelEditDialog(true)}
            onConfirm={(comment) => onSaveChanges(comment)}
          />
        )}
        {(columns.length > 0 || autoGenerateColumns) && (
          <div style={gridContainerStyles} className={`${showEditDialog ? "editing" : ""} ems-grid-container ag-theme-base`}>
            <AgGridReact
              headerHeight={85}
              enableRangeSelection={true}
              enableFillHandle={true}
              suppressMultiRangeSelection={true}
              suppressContextMenu={disableContextMenu}
              suppressScrollWhenPopupsAreOpen={true}
              undoRedoCellEditing={true}
              undoRedoCellEditingLimit={50}
              rowSelection="multiple"
              ref={gridRef}
              rowData={gridData}
              columnDefs={columns}
              getRowClass={getRowClass}
              getContextMenuItems={getContextMenuItems}
              onGridReady={onGridReady}
              onRangeSelectionChanged={onSelectionChange}
              onBodyScroll={onBodyScroll}
              getRowId={getRowId}
              readOnlyEdit={isReadOnly}
              onCellValueChanged={onCellValueChanged}
            ></AgGridReact>
          </div>
        )}

        {isLoadingNext && (
          <Box
            justifyContent="center"
            p={1}
            textAlign="center"
            bg="ui.0"
            opacity={0.8}
            position="fixed"
            bottom="0"
            zIndex="3"
          >
            <Loading size={"sm"} />
          </Box>
        )}

        {isRecalculating && (
          <Box pos="absolute" top="0" left="0" height="100%" zIndex="3">
            <Loading bg="rgba(0, 0, 0, 0.1)" text="" w="99vw" />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default EMSGrid;
export type { EMSGridProps, EMSGridColumn };
