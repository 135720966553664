import React from "react";
import { Flex, Box, Tabs, TabList, Tab } from "@chakra-ui/react";
import { Heading } from "components/common/Heading";
import { Header } from "components/layout/Header";
import { ControlsBar } from "components/layout/ControlsBar";
import { useAppContext } from "contexts/AppContext";
import { useMetersContext } from "contexts/MetersContext";
import { useGridContext } from "contexts/GridContext";
import { useDateContext } from "contexts/DateContext";
import { Modal } from "components/common/Modal";
import { VersionHistory } from "components/VersionHistory";
import { useRouter } from "hooks/useRouter";
import { convertToUtc } from "utils/Utils";

interface Props {
  title: string;
  tabs?: string[];
  tabLocation?: string[];
  defaultTab?: number;
  onSearch?: () => void;
  showActions?: boolean;
  showFilter?: boolean;
  backButton?: boolean;
}

export const AppLayout: React.FC<Props> = ({
  title,
  tabs,
  tabLocation,
  defaultTab,
  onSearch,
  showActions = false,
  showFilter,
  children,
}) => {
  const {
    headerHeight,
    setHeaderHeight,
    showModal,
    setShowModal,
    instanceId,
  } = useAppContext();
  const { meterId } = useMetersContext();
  const { selectedRows } = useGridContext();
  const { fromDate, toDate } = useDateContext();
  const router = useRouter();
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      setHeaderHeight(Math.floor(ref.current.getBoundingClientRect().top) + 1);
    }
  }, [ref, setHeaderHeight]);

  const tabClickEvent = (i: number) => {
    router.push(
      tabLocation &&
      `/${instanceId}/${meterId}/${tabLocation[i]}?fromDate=${convertToUtc(
        fromDate
      ).toISOString()}&toDate=${convertToUtc(toDate).toISOString()}`
    );
  };

  return (
    <Flex flexDirection="column" height="100vh" width="100vw">
      <Header>
        <Flex justifyContent="space-between" flex="1 0 auto">
          <Box mx={5}>
            <Heading as="h2" size="xxs" color="ui.0" mt={2}>
              {title}
            </Heading>
            {tabs && (
              <Tabs defaultIndex={defaultTab}>
                <TabList border={0}>
                  {tabs.map((tab, i) => (
                    <Tab
                      key={i}
                      fontSize="12px"
                      fontWeight="400"
                      px={1}
                      mr={2}
                      py={1}
                      isDisabled={i > 1} // FIXME: temp disable all expect the first two tabs
                      _selected={{
                        color: "ui.0",
                        borderBottom: "2px",
                        borderBottomColor: "ui.0",
                      }}
                      color="ui.0"
                      _disabled={{
                        color: "brand.700",
                      }}
                      onClick={() => tabLocation && tabClickEvent(i)}
                    >
                      {tab}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            )}
          </Box>
        </Flex>
      </Header>
      <ControlsBar
        onSearch={onSearch}
        showActions={showActions}
        showFilter={showFilter}
      />
      <Flex height={`calc(100vh - ${headerHeight}px)`} ref={ref}>
        {children}
      </Flex>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={`Version History for ${selectedRows[0] ? selectedRows[0]?.meter_id : ""
          }`}
        size={"full"}
      >
        <VersionHistory />
      </Modal>
    </Flex>
  );
};
