import { apiClient } from "api/APIUtils";
import { AxiosResponse } from "axios";
import { Measurement, RecalculatePayload } from "models/Measurement/";
import { MeterConfig } from "models/MeterConfig";
import buildQuery from "odata-query";

interface MeasurementsResponse {
  values: Measurement[];
  nextLink: string;
}

export const listDailyMeasurementsAPI = async (
  instanceId: string,
  date: Date,
  meterId?: string
): Promise<MeasurementsResponse> => {
  const query = buildQuery({
    filter: {
      ...(date && { contract_day: date }),
      ...(meterId && { meter_id: meterId }),
    },
  });

  const response = await apiClient.get(
    `/${instanceId}/measurements/daily${query}`
  );

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MeasurementsResponse;
};

export const listMeasurementHistoryAPI = async (
  instanceId: string,
  startDate: Date,
  endDate: Date,
  period: string,
  meterId?: string
): Promise<MeasurementsResponse> => {
  const query = buildQuery({
    filter: {
      contract_day: { ge: startDate, le: endDate },
      ...(meterId && { meter_id: meterId }),
    },
  });
  const response = await apiClient.get(
    `/${instanceId}/measurements/${period}${query}`
  );

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MeasurementsResponse;
};

export const listVersionMeasurementsAPI = async (
  instanceId: string,
  date: Date,
  period: string,
  meterId?: string
): Promise<MeasurementsResponse> => {
  const query =
    period === "hourly"
      ? `?$filter=timestamp%20eq%20${date}%20and%20meter_id%20eq%20%27${meterId}%27%20and%20versions=true`
      : `?$filter=contract_day%20eq%20${date}%20and%20meter_id%20eq%20%27${meterId}%27%20and%20versions=true`;
  const response = await apiClient.get(
    period === "hourly"
      ? `/${instanceId}/measurements/hourly${query}`
      : `/${instanceId}/measurements/daily${query}`
  );

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MeasurementsResponse;
};

export const getMoreMeasurementsAPI = async (
  getMoreMeasurementsUrl: string
): Promise<MeasurementsResponse> => {
  const response = await apiClient.get(getMoreMeasurementsUrl);

  return {
    values: response.data.value,
    nextLink: response.data["@odata.nextLink"],
  } as MeasurementsResponse;
};

export const listMeterConfigsAPI = async (
  instanceId: string,
  id: string,
  date: Date
): Promise<MeterConfig[]> => {
  const query = buildQuery({ filter: { contract_day: date } });
  const response = await apiClient.get(
    `/${instanceId}/meters/${id}/configs${query}`
  );
  return response.data.value;
};

export const updateMeasurementsAPI = async (
  instanceId: string,
  payload: string
): Promise<AxiosResponse> => {
  const response = await apiClient.post(`/${instanceId}/$batch`, payload, {
    headers: { "Content-Type": "text/plain" },
  });
  return response.data;
};

// TODO: Rewrite the types for the fetch
// ): Promise<Measurement[]> => {
export const recalculateAPI = async (
  instanceId: string,
  payload: RecalculatePayload
): Promise<any> => {
  const MAX_TRIES = 1;
  const fetch = async () => {
    for (let count = 0; count <= MAX_TRIES; count++) {
      try {
        const response = await apiClient.post(
          `/${instanceId}/calculate`,
          payload,
          {
            headers: { "Content-Type": "text/plain" },
          }
        );
        return response.data as Measurement[];
      } catch (err) {
        if (count < MAX_TRIES) {
          continue;
        } else {
          return err;
        }
      }
    }
  };

  return await fetch();
};
