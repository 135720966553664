import React from "react";
import { Heading as ChakraUiHeading, HeadingProps as ChakraHeadingProps } from "@chakra-ui/react";

interface HeadingOverride {
  fontSize: string;
  lineHeight: string;
  letterSpacing: string;
  size?: string;
}

interface HeadingProps {
  size?: string;
  fontWeight?: string;
  color?: string;
  as?: string;
}

export const Heading: React.FC<HeadingProps & ChakraHeadingProps> = ({
  children,
  size,
  as,
  ...props
}) => {
  // if no size provided, set a default heading size
  if (!size) {
    size = (() => {
      switch (as) {
        case "h1":
          return "2xl";
        case "h2":
          return "xl";
        case "h3":
          return "lg";
        case "h4":
          return "md";
        case "h5":
          return "sm";
        case "h6":
          return "xs";
        default:
          return "md";
      }
    })();
  }

  // override the ChakraUI theme to match the designs
  const theme = (fontSize: string): HeadingOverride => {
    switch (fontSize) {
      case "2xl":
        return {
          fontSize: "36px",
          lineHeight: "58px",
          letterSpacing: "-0.79px",
        };
      case "xl":
        return {
          fontSize: "32px",
          lineHeight: "48px",
          letterSpacing: "-0.69px",
        };
      case "lg":
        return {
          fontSize: "28px",
          lineHeight: "44px",
          letterSpacing: "-0.59px",
        };
      case "sm":
        return {
          fontSize: "20px",
          lineHeight: "30px",
          letterSpacing: "-0.33px",
        };
      case "xs":
        return {
          fontSize: "18px",
          lineHeight: "26px",
          letterSpacing: "-0.26px",
        };
      case "xxs":
        return {
          fontSize: "16px",
          lineHeight: "26px",
          letterSpacing: "-0.26px",
        };
      default:
      case "md":
        return {
          fontSize: "24px",
          lineHeight: "34px",
          letterSpacing: "-0.47px",
        };
    }
  };

  return (
    <ChakraUiHeading
      boxSize={size}
      as={as}
      color="ui.1000"
      fontSize={theme(size).fontSize}
      fontWeight={600}
      lineHeight={theme(size).lineHeight}
      letterSpacing={theme(size).letterSpacing}
      height={theme(size).lineHeight}
      width="auto"
      {...props}
    >
      {children}
    </ChakraUiHeading>
  );
};
