import React from "react";

export interface GridContextProps {
  grid: any;
  setGrid: Function;
  selectedRows: any[];
  setSelectedRows: Function;
  clearSelectedRows: Function;
  openDrawer: boolean;
  setOpenDrawer: Function;
  recalculateCount: number;
  setRecalculateCount: Function;
}

export const GridContext = React.createContext<GridContextProps>({
  grid: null,
  setGrid: Function,
  selectedRows: [],
  setSelectedRows: Function,
  clearSelectedRows: Function,
  openDrawer: false,
  setOpenDrawer: Function,
  recalculateCount: 0,
  setRecalculateCount: Function,
});

export const useGridContext = () => React.useContext(GridContext);

export const GridProvider: React.FC = ({ children }) => {
  const [grid, setGrid] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [recalculateCount, setRecalculateCount] = React.useState<number>(0);

  const clearSelectedRows = (gridFromCaller?: any) => {
    setSelectedRows([]);

    // const s = grid ? grid : gridFromCaller;
    // s.deferUpdate(() => {
    //   setSelectedRows([]);
    //   s.rows.map((row: any) => (row.isSelected = false));
    // });
  };

  return (
    <GridContext.Provider
      value={{
        grid,
        setGrid,
        selectedRows,
        setSelectedRows,
        clearSelectedRows,
        openDrawer,
        setOpenDrawer,
        recalculateCount,
        setRecalculateCount,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
