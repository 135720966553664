import React from "react";
import {
  Flex,
  Skeleton as ChakraUISkeleton,
  SkeletonProps as ChakraUISkeletonProps,
} from "@chakra-ui/react";

export type Props = {
  cols?: number;
  rows?: number;
  header?: boolean;
};

export const Skeleton: React.FC<Props & ChakraUISkeletonProps> = ({
  cols = 1,
  rows = 1,
  header = false,
  ...props
}) => (
  <Flex m={5} flexDirection="column" width="100%">
    {header && (
      <ChakraUISkeleton
        height={12}
        my={1}
        mx={1}
        borderRadius={2}
        startColor="ui.100"
        endColor="ui.200"
        {...props}
      />
    )}
    {[...Array(rows)].map((_, i) => {
      return (
        <Flex key={`row-${i}`}>
          {[...Array(cols)].map((_, i) => (
            <ChakraUISkeleton
              key={`col-${i}`}
              height={8}
              my={1}
              borderRadius={1}
              startColor="ui.200"
              endColor="ui.100"
              width="50%"
              mx={1}
              {...props}
            />
          ))}
        </Flex>
      );
    })}
  </Flex>
);
