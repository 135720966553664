import React from "react";
import { Box, Button, MenuButton, MenuList, Menu, MenuItem } from "@chakra-ui/react";
import { useAppContext } from "contexts/AppContext";
import { BiChevronDown } from "react-icons/bi";

export const Instances: React.FC = () => {
  const { instances, instanceId, setInstanceId } = useAppContext();

  const instanceName = () => {
    const currentInstance = instances.find((x) => x.id === instanceId);
    return currentInstance && currentInstance.name;
  };
  return (
    <Box pr="2">
      <Menu>
        <MenuButton
          as={Button}
          h={8}
          px={3}
          borderRadius={3}
          fontSize="sm"
          fontWeight={400}
          bgColor="ui.0"
          color="ui.1000"
          _active={{ bg: "brand.100" }}
          _hover={{ bg: "brand.100" }}
          rightIcon={<BiChevronDown size="1.2em" />}
        >
          {instances ? instanceName() : "Loading..."}
        </MenuButton>
        <MenuList zIndex="102">
          {instances &&
            instances.map((instance, i) => {
              return (
                <MenuItem
                  key={i}
                  fontSize="14px"
                  onClick={() => {
                    setInstanceId(instance.id);
                    localStorage.setItem("instanceId", instance.id);
                    window.location.reload();
                  }}
                >
                  {instance.name}
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu>
    </Box>
  );
};
