import React from "react";
import { logEventSourceAPI } from "api/EventSourceLoggingAPI";
import { EventSourceLogging } from "models/EventSourceLogging";

export interface EventSourceLoggingContextProps {
  logEventSource: Function;
}

export const EventSourceLoggingContext = React.createContext<EventSourceLoggingContextProps>({
  logEventSource: Function,
});

export const useEventSourceLoggingContext = () =>
  React.useContext(EventSourceLoggingContext);

export const EventSourceLoggingProvider: React.FC = ({ children }) => {
  const logEventSource = async (eventSource: EventSourceLogging) => {
    try {
      await logEventSourceAPI(eventSource);
      return "success";
    } catch (err) {
      console.error("errored", err);
    }
  };

  return (
    <EventSourceLoggingContext.Provider
      value={{
        logEventSource,
      }}
    >
      {children}
    </EventSourceLoggingContext.Provider>
  );
};
