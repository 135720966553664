import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Box, useTheme } from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button } from "components/common/Button";
import { InputText } from "components/common/InputText";
import { useToasts } from "contexts/ToastContext";

interface ConfirmEditDialogProps {
  onClose: () => void;
  onConfirm: (comment: string) => void;
}

export const ConfirmEditDialog: React.FC<ConfirmEditDialogProps> = ({
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { setToast } = useToasts();

  const validateComment = (comment: string) => {
    if (comment === "") {
      setToast({
        status: "warning",
        message: t("components.confirmEditDialog.errorMissingComment"),
      });
    }
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      bg="ui.0"
      height="48px"
      boxShadow={`0px -2px 6px 0 ${colors.ui[500]}`}

      zIndex={1000}
    >
      <Flex
        position="relative"
        margin="0 auto"
        p="8px"
        alignItems="center"
        height="48px"
        width={["300px", "596px"]}
      >
        <Formik
          initialValues={{ comment: "" }}
          validationSchema={Yup.object().shape({
            comment: Yup.string().required(" "), // design doesn't have space for error message, so leave blank.
          })}
          onSubmit={({ comment }) => onConfirm(comment)}
          validateOnChange={false}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <Box width="100%">
              <form onSubmit={handleSubmit}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Field name="comment" validate={validateComment}>
                    {({ field, form: { errors, touched } }: any) => (
                      <>
                        <InputText
                          id="comment"
                          {...field}
                          size="sm"
                          placeholder={t(
                            "components.confirmEditDialog.addComment"
                          )}
                          validationError={errors.comment}
                        />
                      </>
                    )}
                  </Field>
                  <Box minWidth="240px" textAlign="right">
                    <Button
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      variant="outline"
                      size="sm"
                      ml={2}
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      isLoading={isSubmitting}
                      type="submit"
                      variant="solid"
                      size="sm"
                      ml={2}
                    >
                      {t("components.confirmEditDialog.saveChanges")}
                    </Button>
                  </Box>
                </Flex>
              </form>
            </Box>
          )}
        </Formik>
      </Flex>
    </Box>
  );
};
