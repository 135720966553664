import React from "react";
import { useMetersContext } from "contexts/MetersContext";
import { useGridContext } from "contexts/GridContext";
import { useDateContext } from "contexts/DateContext";
import { Branch } from "components/common/Branch";
import { MeterConfiguration } from "components/meters/MeterConfiguration";
import { MeterDrawerLoading } from "components/meters/MeterDrawerLoading";
import { Error } from "components/common/Error";

export const MeterConfigPanel: React.FC = () => {
  const { selectedRows } = useGridContext();
  const { selectedDate } = useDateContext();

  const {
    listMeterConfigStatus,
    meterConfigs,
    listMeterConfigs,
  } = useMetersContext();

  const fetchData = React.useCallback(
    async (id: string) => {
      await listMeterConfigs(id, new Date(selectedDate));
    },
    [listMeterConfigs, selectedDate]
  );

  React.useEffect(() => {
    if (selectedRows && selectedRows.length === 1) {
      const id = selectedRows[0].meter_id;
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [selectedRows]);

  return (
    <>
      {selectedRows && (
        <Branch
          isLoading={listMeterConfigStatus === "loading"}
          hasError={listMeterConfigStatus === "error"}
          ErrorComponent={() => (
            <Error retry={() => fetchData(selectedRows[0].meter_id)} />
          )}
          LoadingComponent={() => <MeterDrawerLoading />}
          Component={() => <MeterConfiguration configs={meterConfigs} />}
        />
      )}
    </>
  );
};
