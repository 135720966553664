import { apiClient } from "api/APIUtils";
import { EventSourceLogging } from "models/EventSourceLogging";

export const logEventSourceAPI = async (payload: EventSourceLogging): Promise<any> => {
  const response = await apiClient.post(`/error-logging`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response as any;
};
