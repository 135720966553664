import { FC, useRef } from "react";
import { useDateContext } from "contexts/DateContext";
import { Box } from "@chakra-ui/react";
import ReactCalendar from "react-calendar";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

import useOnClickOutside from "hooks/useOnClickOutside";
import { useRouter } from "hooks/useRouter";
import { convertToUtc } from "utils/Utils";

import './Calendar.scss';

export enum CalendarFor {
  From = "from",
  To = "to",
  Selected = "selected",
}

interface Props {
  closeDateBox: () => void;
  calendarFor: string;
}

export const Calendar: FC<Props> = ({ closeDateBox, calendarFor }) => {
  const {
    setSelectedDate,
    selectedDate,
    setFromDate,
    fromDate,
    setToDate,
    toDate,
  } = useDateContext();
  const router = useRouter();
  const calendarElementRef = useRef(null);

  const getSelectedDate = () => {
    switch (calendarFor) {
      case CalendarFor.From: {
        return fromDate;
      }
      case CalendarFor.To: {
        return toDate;
      }
      default: {
        return selectedDate;
      }
    }
  };

  const handleChange = (date: any) => {
    const dateForApi = date;
    const dateForUrl = convertToUtc(date).toISOString();

    switch (calendarFor) {
      case CalendarFor.From: {
        if (date !== fromDate) {
          setFromDate(dateForApi);
          router.push(
            `${router.pathname}?fromDate=${dateForUrl}&toDate=${convertToUtc(
              toDate
            ).toISOString()}`
          );
          closeDateBox();
        }
        break;
      }
      case CalendarFor.To: {
        if (date !== toDate) {
          setToDate(dateForApi);
          router.push(
            `${router.pathname}?fromDate=${convertToUtc(
              fromDate
            ).toISOString()}&toDate=${dateForUrl}`
          );
          closeDateBox();
        }
        break;
      }
      default: {
        if (date !== selectedDate) {
          setSelectedDate(dateForApi);
          router.push(`${router.pathname}?date=${dateForUrl}`);
          closeDateBox();
        }
        break;
      }
    }
  };

  useOnClickOutside(calendarElementRef, closeDateBox);

  return (
    <Box position={"absolute"} left={"50%"} transform={"translateX(-50%)"}>
      <ReactCalendar
        inputRef={calendarElementRef}
        onChange={handleChange}
        value={getSelectedDate()}
        minDate={calendarFor === CalendarFor.To ? fromDate : undefined}
        maxDate={calendarFor === CalendarFor.From ? toDate : new Date(Date.now())}
        prevLabel={<FaCaretLeft className="prevLabel" />}
        nextLabel={<FaCaretRight className="nextLabel" />}
        prev2Label={null}
        next2Label={null}
      />
    </Box>
  );
};
