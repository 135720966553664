import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import App from "components/App";
import { GlobalStyle } from "components/GlobalStyle";
import { ToastProvider } from "contexts/ToastContext";
import { AppProvider } from "contexts/AppContext";
import { DataProvider } from "contexts/DataContext";
import { DateProvider } from "contexts/DateContext";
import { QuicksightProvider } from "contexts/QuicksightContext";
import { MetersProvider } from "contexts/MetersContext";
import { GridProvider } from "contexts/GridContext";
import { ErrorLoggingProvider } from "contexts/ErrorLoggingContext";

import theme from "theme";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ChakraProvider theme={theme} resetCSS={true}>
    <ErrorLoggingProvider>
      <ToastProvider>
        <AppProvider>
          <GlobalStyle />
          <DateProvider>
            <DataProvider>
              <QuicksightProvider>
                <MetersProvider>
                  <GridProvider>
                    <App />
                  </GridProvider>
                </MetersProvider>
              </QuicksightProvider>
            </DataProvider>
          </DateProvider>
        </AppProvider>
      </ToastProvider>
    </ErrorLoggingProvider>
  </ChakraProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
