import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Box, Flex, FormControl, FormLabel, Button, Select } from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";

import IconLink from "components/common/IconLink";
import { InputText } from "components/common/InputText";
import { Text } from "components/common/Text";
import { Heading } from "components/common/Heading";
import { useAppContext } from "contexts/AppContext";
import { useToasts } from "contexts/ToastContext";
import { useEventSourceLoggingContext } from "contexts/EventSourceLoggingContext";
import { EventSourceLogging } from "models/EventSourceLogging";

import awsRegions from "api/AWSConsts/regions.json";
import "./EventSourceForm.scss";

const getRegionOptions = () => {
  return awsRegions.map((region) => {
    return { label: `${region.label} | ${region.value}`, value: region.value };
  });
};

const findRegionOption = (value: string) => {
  return awsRegions.find((region) => {
    return value === region.value;
  });
};


export const EventSourceForm: React.FC = () => {
  const { t } = useTranslation();
  const { setToast } = useToasts();
  const { logEventSource } = useEventSourceLoggingContext();
  const { instances, instanceId, user } = useAppContext();

  const defaultRegion = awsRegions.length === 1 ? awsRegions[0].value : "";
  const INITIAL_VALUES = { awsAccountId: "", awsRegion: defaultRegion, object: "SAMPLE", user, instanceId };

  const [submitted, setSubmitted] = useState(false);
  const [submittedValues, setSubmittedValues] = useState(INITIAL_VALUES);

  const regionOptions = getRegionOptions();

  const findInstanceOption = (id: string) => {
    return instances.find((instance) => {
      return id === instance.id;
    });
  };


  return (
    <>
      {!submitted && (
        <Box mt="2" className="event-source">
          <Text fontSize="md">{t("pages.eventSource.description")}</Text>

          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={Yup.object().shape({
              awsAccountId: Yup.string()
                .matches(/^(\d{12})$/, t("common.error.form.awsFormat"))
                .required(t("common.error.form.number")),
              awsRegion: Yup.string().required(t("common.error.form.required")),
            })}
            onSubmit={async ({ awsAccountId, awsRegion, user, instanceId, object }) => {
              try {
                await logEventSource(
                  {
                    logType: "EventSourceSubscription",
                    logSeverity: "Normal",
                    logContent: JSON.stringify({
                      user,
                      instanceId,
                      awsAccountId,
                      awsRegion,
                      object,
                    })
                  } as EventSourceLogging);
                setSubmittedValues({ awsAccountId, awsRegion, user, instanceId, object });
                setSubmitted(true);
              } catch (e) {
                setToast({
                  status: "error",
                  title: t("common.error.genericTitle"),
                  message: t("common.error.genericMessage"),
                });
              }
            }}
            validateOnChange={false}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Field type="hidden" name="user" />
                <Field name="instanceId">
                  {({ field, form: { errors, touched, setFieldValue, values } }: any) => (
                    <FormControl
                      mt="4"
                      pb="6"
                    >
                      <FormLabel
                        color="ui.1000"
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="400"
                        htmlFor="instanceId"
                      >
                        {t("components.eventSource.instanceId")}
                      </FormLabel>
                      <Select
                        {...field}
                        id="instanceId"
                        name="instanceId"
                        icon={<FaCaretDown />}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                          setFieldValue(ev.target.name, ev.target.value);
                        }}
                        value={values.instanceId}
                        size="sm"
                        isInvalid={errors.instanceId && touched.instanceId}
                      >
                        {instances.map(({ id, name }) => <option value={id} key={id}>{name}</option>)}
                      </Select>
                      {errors.awsRegion && touched.awsRegion && (
                        <Flex
                          data-testid="testid-instanceId-validation-error"
                          as="div"
                          fontSize="10px"
                          lineHeight="16px"
                          color="danger.1000"
                          m={1}
                        >
                          {errors.instanceId}
                        </Flex>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="awsAccountId">
                  {({ field, form: { errors, touched } }: any) => (
                    <FormControl
                      pb="6"
                      isInvalid={errors.awsAccountId && touched.awsAccountId}
                    >
                      <InputText
                        {...field}
                        id="awsAccountId"
                        label={t("components.eventSource.awsAccountId")}
                        type="string"
                        value={props.values.awsAccountId}
                        validationError={errors.awsAccountId}
                        onFocus={() => {
                          delete errors["awsAccountId"];
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="awsRegion">
                  {({ field, form: { errors, touched, setFieldValue, values } }: any) => (
                    <FormControl
                      pb="6"
                      isInvalid={errors.awsRegion && touched.awsRegion}
                    >
                      <FormLabel
                        color="ui.1000"
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="400"
                        htmlFor="awsRegion"
                      >
                        {t("components.eventSource.awsRegion")}
                      </FormLabel>
                      <Select
                        {...field}
                        id="awsRegion"
                        name="awsRegion"
                        icon={<FaCaretDown />}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                          setFieldValue(ev.target.name, ev.target.value);
                          if (ev.target.value) delete errors["awsRegion"];
                        }}
                        value={values.awsRegion}
                        isReadOnly={regionOptions.length <= 1}
                        size="sm"
                        isInvalid={errors.awsRegion && touched.awsRegion}
                      >
                        {(regionOptions.length > 1) && (
                          <option value="" key="undefined">{t("components.eventSource.awsRegionSelect")}</option>
                        )}
                        {regionOptions.map(({ value, label }) => <option value={value} key={value}>{label}</option>)}
                      </Select>
                      {errors.awsRegion && touched.awsRegion && (
                        <Flex
                          data-testid="testid-awsRegions-validation-error"
                          as="div"
                          fontSize="10px"
                          lineHeight="16px"
                          color="danger.1000"
                          m={1}
                          className="field-error"
                        >
                          {errors.awsRegion}
                        </Flex>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="object">
                  {({ field, form: { setFieldValue, values } }: any) => (
                    <FormControl
                      pb="6"
                    >
                      <FormLabel
                        color="ui.1000"
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="400"
                        htmlFor="object"
                      >
                        {t("components.eventSource.object")}
                      </FormLabel>
                      <Select
                        {...field}
                        id="object"
                        name="object"
                        icon={<FaCaretDown />}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                          setFieldValue(ev.target.name, ev.target.value);
                        }}
                        value={values.object}
                        isReadOnly={true}
                        size="sm"
                      >
                        <option value="SAMPLE" key="SAMPLE">SAMPLE</option>
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Flex justifyContent="right" mt="3">
                  {(props.errors && Object.keys(props.errors).length > 0) && (
                    <Text fontSize="sm" color="danger.1000" mr="4" lineHeight={8}>{t("components.eventSource.errors", { count: Object.keys(props.errors).length })}</Text>
                  )}
                  <Button
                    variant="solid"
                    px="3"
                    height="32px"
                    isLoading={props.isSubmitting}
                    isDisabled={!props.isValid}
                    type="submit"
                    backgroundColor="brand.1000"
                    _hover={{ bg: "brand.1200" }}
                    color="ui.0"
                  >
                    {t("components.eventSource.subscribe")}
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        </Box>
      )}
      {submitted && (
        <>
          <Text>{t("components.eventSource.success")}</Text>

          <Box
            className="event-source"
            p="4"
            mt="3"
            borderRadius="6px"
            bg="ui.100"
          >
            <dl>
              <dt>{t("components.eventSource.instanceId")}</dt>
              <dd>{`${findInstanceOption(submittedValues.instanceId)?.name} | ${submittedValues.instanceId}`}</dd>
              <dt>{t("components.eventSource.awsAccountId")}</dt>
              <dd>{submittedValues.awsAccountId}</dd>
              <dt>{t("components.eventSource.awsRegion")}</dt>
              <dd>{`${findRegionOption(submittedValues.awsRegion)?.label} | ${submittedValues.awsRegion}`}</dd>
              <dt>{t("components.eventSource.object")}</dt>
              <dd>{submittedValues.object}</dd>
            </dl>
          </Box>
          <Heading as="h6" my="4">{t("components.eventSource.next.title")}</Heading>

          <Text>
            {t("components.eventSource.next.instructions")}&nbsp;
          </Text>
          <Text mt={4}>
            <Trans i18nKey="components.eventSource.next.instructionsLink">
              For more information, please follow the instructions that are available from the <IconLink to="https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-saas.html" external={true}>Amazon EventBridge User Guide</IconLink>.
            </Trans>
          </Text>
          <Text mt={4}>
            <Trans i18nKey="components.eventSource.next.additionalLink">
              For additional information on managing your partner event sources with EnergySys applications, please consult help articles in the <IconLink to="https://support.energysys.com/a/solutions/categories/35000139117/folders/35000228047" external={true}>solutions centre</IconLink>.
            </Trans>
          </Text>
        </>
      )}
    </>
  );
};