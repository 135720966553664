import Axios from "axios";
import { merge } from "lodash";

import { useAuth } from "hooks/useAuth";

export const apiClient = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const { getRefreshingToken } = useAuth();
  const tokenResponse = await getRefreshingToken();

  if (config) {
    merge(config, {
      headers: {
        Authorization: `Bearer ${tokenResponse}`
      }
    });
  }

  return config;
});
