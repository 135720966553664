import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAppContext } from "contexts/AppContext";
import { useDataContext } from "contexts/DataContext";
import { useMetersContext } from "contexts/MetersContext";
import { useDateContext } from "contexts/DateContext";
import { AppLayout } from "components/layout/AppLayout";
import { Skeleton } from "components/common/Skeleton";
import { Branch } from "components/common/Branch";
import { Error } from "components/common/Error";
import { Empty } from "components/common/Empty";
import EMSGrid from "components/common/EMSGrid";
import { useRouter } from "hooks/useRouter";

interface Props {
  timePeriod: string;
}

export const MeasurementHistoryPage: React.FC<Props> = ({ timePeriod }) => {
  const { t } = useTranslation();
  const { metaData, preferences } = useAppContext();
  const router = useRouter();

  const {
    listMeasurementHistory,
    listMeasurementsStatus,
    measurements,
    getMoreMeasurements,
    getMoreMeasurementsUrl,
  } = useDataContext();
  const { meterId, setMeterId } = useMetersContext();
  const { fromDate, toDate, setShowDateRange } = useDateContext();
  const [entityType, setEntityType] = React.useState(null);

  // This must never be updated after load.
  const [defaultTabIndex] = React.useState<number>(
    router.pathname && router.pathname.includes("hourly") ? 0 : 1
  );

  const fetchData = React.useCallback(async () => {
    listMeasurementHistory(
      moment(fromDate).startOf("day").toDate(),
      moment(toDate).endOf("day").toDate(),
      timePeriod,
      meterId
    );
  }, [listMeasurementHistory, fromDate, toDate, timePeriod, meterId]);

  const fetchMoreData = async (url: string) => {
    return await getMoreMeasurements(url);
  };

  React.useEffect(() => {
    if (metaData) {
      setEntityType(metaData.find(
        (y: any) => y.attributes.Name === "Measurement"
      ));
    }
  }, [metaData]);

  // When reloading the meterId will need to be set before fetching data.
  React.useEffect(() => {
    if (meterId) {
      fetchData();
    } else {
      setMeterId(router.query.meterId);
    }

    // eslint-disable-next-line
  }, [JSON.stringify(fromDate), JSON.stringify(toDate), meterId, timePeriod]);

  React.useEffect(() => {
    setShowDateRange(true);
  }, [setShowDateRange]);

  const tabs = [
    t("pages.measurementHistory.tabs.hourlyMeasurements"),
    t("pages.measurementHistory.tabs.dailyMeasurements"),
    t("pages.measurementHistory.tabs.monthlyMeasurements"),
  ];
  const tabLocation = [
    "hourly-measurement-history",
    "daily-measurement-history",
    "monthly-measurement-history",
  ];

  return (
    <AppLayout
      title={t("pages.measurementHistory.title", { meterId })}
      tabs={tabs}
      tabLocation={tabLocation}
      defaultTab={defaultTabIndex}
      backButton
      showActions={true}
    >
      <Branch
        isLoading={listMeasurementsStatus === "loading"}
        LoadingComponent={() => <Skeleton header rows={8} cols={6} />}
        hasError={listMeasurementsStatus === "error"}
        ErrorComponent={() => <Error retry={fetchData} />}
        isEmpty={listMeasurementsStatus === "empty"}
        EmptyComponent={() => <Empty />}
        Component={() => (
          <EMSGrid
            data={measurements}
            preferences={
              router.pathname && router.pathname.includes("hourly")
                ? preferences?.HourlyMeasurement
                : preferences?.DailyMeasurement
            }
            endpoint="measurements/hourly"
            entityType={entityType}
            fetchData={fetchData}
            fetchMoreData={fetchMoreData}
            loadMoreLink={getMoreMeasurementsUrl}
          />
        )}
      />
    </AppLayout>
  );
};
