import React from "react";
import { Box } from "@chakra-ui/react";
import { embedDashboard, embedSession } from "amazon-quicksight-embedding-sdk";

interface Props {
  url: string;
}

export const Dashboard: React.FC<Props> = ({ url }) => {
  const myRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (url) {
      if (process.env.REACT_APP_QUICKSIGHT_USE_SESSION === "true") {
        embedSession({
          url: url,
          container: myRef.current,
        });
      } else {
        embedDashboard({
          url: url,
          container: myRef.current,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return url ? <Box width="100%" ref={myRef} /> : <Box />;
};
