import React from "react";
import { Text as ChakraUiText, BoxProps as ChakraBoxProps } from "@chakra-ui/react";

interface TextOverride {
  fontSize: string;
  lineHeight: string;
  letterSpacing: string;
}

interface TextProps {
  fontWeight?: string;
  fontSize?: string;
  bgColor?: string;
  as?: string;
  mb?: number;
  my?: number;
  pr?: number;
  px?: number;
  py?: number;
}

// override the ChakraUI theme to match the designs
const theme = (size: any): TextOverride => {
  switch (size) {
    case "6xl":
      return {
        fontSize: "36px",
        lineHeight: "1.61",
        letterSpacing: "-0.79px",
      };
    case "5xl":
      return {
        fontSize: "32px",
        lineHeight: "1.5",
        letterSpacing: "-0.69px",
      };
    case "4xl":
      return {
        fontSize: "28px",
        lineHeight: "1.57",
        letterSpacing: "-0.59px",
      };
    case "3xl":
      return {
        fontSize: "24px",
        lineHeight: "1.42",
        letterSpacing: "-0.47px",
      };
    case "2xl":
      return {
        fontSize: "32px",
        lineHeight: "1.5",
        letterSpacing: "-0.33px",
      };
    case "xl":
      return {
        fontSize: "18px",
        lineHeight: "1.44",
        letterSpacing: "-0.26px",
      };
    case "md":
      return {
        fontSize: "14px",
        lineHeight: "1.29",
        letterSpacing: "-0.09px",
      };
    case "sm":
      return {
        fontSize: "12px",
        lineHeight: "1.33",
        letterSpacing: "-0.01px",
      };
    case "xs":
      return {
        fontSize: "10px",
        lineHeight: "1.6",
        letterSpacing: "0.01px",
      };
    default:
    case "lg":
      return {
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "-0.18px",
      };
  }
};

export const Text: React.FC<TextProps & ChakraBoxProps> = ({
  children,
  as = "p",
  fontWeight = "400",
  fontSize = "lg",
  ...props
}) => {
  return (
    <ChakraUiText
      as={as}
      color="ui.1000"
      fontWeight={fontWeight}
      fontSize={theme(fontSize).fontSize}
      lineHeight={theme(fontSize).lineHeight}
      letterSpacing={theme(fontSize).letterSpacing}
      {...props}
    >
      {children}
    </ChakraUiText>
  );
};
