import { FC, createContext, useContext, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  listDailyMeasurementsAPI,
  listMeasurementHistoryAPI,
  listVersionMeasurementsAPI,
  getMoreMeasurementsAPI,
  updateMeasurementsAPI,
  recalculateAPI,
} from "api/DataAPI";
import { getUid, convertToUtc } from "utils/Utils";
import { useAppContext } from "contexts/AppContext";
import { useToasts } from "contexts/ToastContext";
import { BranchStatus } from "components/common/Branch";
import {
  Measurement,
  MeasurementPayload,
  RecalculatePayload,
} from "models/Measurement";

export interface DataContextProps {
  listDailyMeasurements: Function;
  listMeasurementHistory: Function;
  listVersionMeasurements: Function;
  listMeasurementsStatus: BranchStatus;
  listVersionMeasurementsStatus: BranchStatus;
  measurements: Measurement[];
  versionMeasurements: Measurement[];
  updateMeasurements: Function;
  getMoreMeasurementsUrl: string;
  setMoreMeasurementsUrl: Function;
  getMoreMeasurements: Function;
  recalculateMeasurements: Function;
}

export const DataContext = createContext<DataContextProps>({
  listDailyMeasurements: Function,
  listMeasurementHistory: Function,
  listVersionMeasurements: Function,
  listMeasurementsStatus: BranchStatus.Idle,
  listVersionMeasurementsStatus: BranchStatus.Idle,
  measurements: [],
  versionMeasurements: [],
  updateMeasurements: Function,
  getMoreMeasurementsUrl: "",
  setMoreMeasurementsUrl: Function,
  getMoreMeasurements: Function,
  recalculateMeasurements: Function,
});

export const useDataContext = () => useContext(DataContext);

export const DataProvider: FC = ({ children }) => {
  const { instanceId } = useAppContext();
  const { t } = useTranslation();
  const { setToast } = useToasts();
  const [measurements, setMeasurements] =
    useState<Measurement[]>([]);
  const [versionMeasurements, setVersionMeasurements] =
    useState<Measurement[]>([]);
  const [listMeasurementsStatus, setListMeasurementsStatus] =
    useState<BranchStatus>(BranchStatus.Idle);
  const [listVersionMeasurementsStatus, setListVersionMeasurementsStatus] =
    useState<BranchStatus>(BranchStatus.Idle);
  const [getMoreMeasurementsUrl, setMoreMeasurementsUrl] =
    useState<string>("");

  const listDailyMeasurements = async (contractDay: Date, meterId: string) => {
    try {
      setListMeasurementsStatus(BranchStatus.Loading);
      const response = await listDailyMeasurementsAPI(
        instanceId,
        convertToUtc(contractDay),
        meterId
      );
      setMeasurements(response.values);
      if (!response.values.length) {
        setListMeasurementsStatus(BranchStatus.Empty);
      } else {
        setMoreMeasurementsUrl(response.nextLink ? response.nextLink : "");
        setListMeasurementsStatus(BranchStatus.Finished);
      }
    } catch {
      setListMeasurementsStatus(BranchStatus.Error);
    }
  };

  const listMeasurementHistory = async (
    startDate: Date,
    endDate: Date,
    period: string,
    meterId: string
  ) => {
    try {
      setListMeasurementsStatus(BranchStatus.Loading);
      const startDateUst = convertToUtc(startDate);
      const addDayToEndDate = moment(
        moment(endDate).add(1, "days"),
        moment.defaultFormat
      ).toDate();
      const endDateUst = convertToUtc(
        period === "hourly" ? addDayToEndDate : endDate
      );

      const response = await listMeasurementHistoryAPI(
        instanceId,
        startDateUst,
        endDateUst,
        period,
        meterId
      );
      setMeasurements(response.values);
      if (!response.values.length) {
        setListMeasurementsStatus(BranchStatus.Empty);
      } else {
        setMoreMeasurementsUrl(response.nextLink ? response.nextLink : "");
        setListMeasurementsStatus(BranchStatus.Finished);
      }
    } catch {
      setListMeasurementsStatus(BranchStatus.Error);
    }
  };

  const getMoreMeasurements = async (url: string) => {
    try {
      const response = await getMoreMeasurementsAPI(url);

      const formattedResponse = [
        ...measurements,
        ...response.values,
      ];

      setMeasurements(formattedResponse);
      setMoreMeasurementsUrl(response.nextLink ? response.nextLink : "");

      return "success";
    } catch (e) {
      console.error(e);
      setToast({
        status: "error",
        title: t("common.error.genericTitle"),
        message: t("common.error.genericMessage"),
      });
      return "fail";
    }
  };

  /** Passing meterID (as id) as it is not always stored in context (when on daily page without meter search active) */
  const listVersionMeasurements = async (
    contractDay: Date,
    period: string,
    meterId: string
  ) => {
    try {
      setListVersionMeasurementsStatus(BranchStatus.Loading);
      const response = await listVersionMeasurementsAPI(
        instanceId,
        contractDay,
        period,
        meterId
      );
      setVersionMeasurements(response.values);
      if (!response.values.length) {
        setListVersionMeasurementsStatus(BranchStatus.Empty);
      } else {
        setMoreMeasurementsUrl(response.nextLink ? response.nextLink : "");
        setListVersionMeasurementsStatus(BranchStatus.Finished);
      }
    } catch {
      setListVersionMeasurementsStatus(BranchStatus.Error);
    }
  };

  const formatMeasurementPayload = ({
    audit,
    requests,
  }: MeasurementPayload): string => {
    const CRLF = "\r\n";
    const BATCH_UID = getUid();
    const CHANGESET_UID = getUid();

    return [
      `--batch_${BATCH_UID}`,
      `Content-Type: multipart/mixed; boundary=changeset_${CHANGESET_UID}`,
      ``,
      `--changeset_${CHANGESET_UID}`,
      `Content-Type: application/json`,
      `Content-Transfer-Encoding: binary`,
      ``,
      `POST audit HTTP/1.1`,
      ``,
      JSON.stringify({ audit }),
      ``,
      ...requests
        .map(({ method, endpoint, measurement }) => [
          `--changeset_${CHANGESET_UID}`,
          `Content-Type: application/json`,
          `Content-Transfer-Encoding: binary`,
          ``,
          `${method} ${endpoint} HTTP/1.1`,
          ``,
          JSON.stringify(measurement),
          ``,
        ])
        .flat(),
      `--changeset_${CHANGESET_UID}--`,
      `--batch_${BATCH_UID}--`,
    ].join(CRLF);
  };

  const updateMeasurements = async (
    payload: MeasurementPayload,
    onSuccess: () => void
  ) => {
    try {
      await updateMeasurementsAPI(
        instanceId,
        formatMeasurementPayload(payload)
      );
      onSuccess();
      setToast({
        status: "success",
        title: t("components.confirmEditDialog.saveSuccess"),
      });
    } catch (err) {
      setToast({
        status: "error",
        title: t("components.confirmEditDialog.saveError"),
      });
    }
  };

  const recalculateMeasurements = async (payload: RecalculatePayload) => {
    try {
      const response = await recalculateAPI(
        instanceId,
        payload
      );

      if (response?.length) {
        return response;
      } else {
        throw response;
      }
    } catch (err) {
      console.error("error occurred", err);
      setToast({
        status: "error",
        title: t("components.kcclCalculation.error"),
      });
      return err;
    }
  };

  return (
    <DataContext.Provider
      value={{
        listDailyMeasurements,
        listMeasurementHistory,
        listVersionMeasurements,
        listMeasurementsStatus,
        listVersionMeasurementsStatus,
        measurements,
        versionMeasurements,
        updateMeasurements,
        getMoreMeasurementsUrl,
        setMoreMeasurementsUrl,
        getMoreMeasurements,
        recalculateMeasurements,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
