import React from "react";
import { Flex } from "@chakra-ui/react";
import { Instances } from "components/instances";
import { IconList } from "components/GlobalActionsPanel/IconList";

export const GlobalActionsWrapper: React.FC = () => {
  return (
    <Flex minWidth="150px" padding="8px 15px" justifyContent="space-between">
      <Instances />
      <IconList />
    </Flex>
  );
};
