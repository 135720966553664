import { LoginPayload, loginAPI } from "api/AuthAPI";
import { CognitoAuth } from "amazon-cognito-auth-js";

const DEFAULT_AUTH_ROUTE = "/dashboard";
const LOGIN = "/login";

export const useAuth = () => {
  const getRedirectRoute = () => {
    return sessionStorage.getItem("redirectPath") || DEFAULT_AUTH_ROUTE;
  };

  const getAuthToken = () => {
    const token = Object.entries(window.localStorage).find((o) =>
      o[0].includes("idToken")
    );
    return token ? token[1] : null;
  };

  const getRefreshingToken = async () => {
    // Get token form local storage because this always stays the same
    let token = getJWTToken();

    const client_id = window.localStorage.getItem("client_id") || "";
    const idp = window.localStorage.getItem("idp") || "";

    //create an auth instance to work with and get the cached session
    const auth = await initCognitoSDK(client_id, idp);
    const user = await auth.getCachedSession();

    //uncomment out this console log for bug fixing permission of users
    //console.log("user", user);

    // If token has expired get refresh token and refresh session
    // Delay is required to update the token in localStorage otherwise consequent calls will fail
    if (!user.isValid()) {
      await auth.refreshSession(user.getRefreshToken().getToken());
      await timeout(1000);
      token = await getJWTToken();
    }

    return token ? token[1] : null;
  };

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getJWTToken = () => {
    return Object.entries(window.localStorage).find((o) =>
      o[0].includes("idToken")
    );
  };

  const isAuthenticated = () => getAuthToken() !== null;

  const clearLocalStorage = () => {
    window.localStorage.removeItem("isAuthenticating");
  };

  const initCognitoSDK = (clientId: string, idp: string): CognitoAuth => {
    const redirectUri = `${window.location.protocol}//${window.location.host}/`;
    const auth = new CognitoAuth({
      ClientId: clientId,
      AppWebDomain: idp,
      TokenScopesArray: ["openid", "email", "profile"],
      AdvancedSecurityDataCollectionFlag: false,
      RedirectUriSignIn: redirectUri,
      RedirectUriSignOut: redirectUri,
    });

    auth.useCodeGrantFlow();

    auth.userhandler = {
      onSuccess: () => {
        clearLocalStorage();
        // Because this success handler some gets attached to the instance used
        // for the refresh token, check if we need to do a redirect before doing it
        if (window.location.search.indexOf("code=") > -1) {
          const redirectTo = getRedirectRoute();
          sessionStorage.removeItem("redirectPath");
          localStorage.removeItem("errorLogged");
          window.location.href = redirectTo;
        }
      },
      onFailure: (e) => {
        console.error(e);
        const errorLogged = localStorage.getItem("errorLogged");

        if (errorLogged || errorLogged === undefined) {
          // Do nothing until error logged is cleared - then we know it is a new error.
          // This prevents an infinite loop.
        } else {
          Object.keys(localStorage).map(
            (key) =>
              key.includes("CognitoIdentityServiceProvider") &&
              localStorage.removeItem(key)
          );
          localStorage.setItem("errorLogged", "true");
        }
      },
    };

    return auth;
  };

  const login = async (payload: LoginPayload) => {
    const {
      data: { client_id, idp },
    } = await loginAPI(payload);

    localStorage.setItem("user", payload.email);
    localStorage.setItem("isAuthenticating", "true");
    localStorage.setItem("client_id", client_id);
    localStorage.setItem("idp", idp);

    // this results in a redirect away from the react app to OKTA login.
    const auth = initCognitoSDK(client_id, idp);
    auth.getSession();
  };

  const createAuthTokens = () => {
    const client_id = window.localStorage.getItem("client_id") || "";
    const idp = window.localStorage.getItem("idp") || "";
    const auth = initCognitoSDK(client_id, idp);
    auth.parseCognitoWebResponse(window.location.href);
  };

  const redirect = isAuthenticated()
    ? getRedirectRoute()
    : localStorage.getItem("isAuthenticating") !== "true"
      ? LOGIN
      : null;

  return {
    getAuthToken,
    getRefreshingToken,
    isAuthenticated,
    login,
    createAuthTokens,
    redirect,
  };
};
