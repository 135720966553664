import React from "react";
import { MeterConfigPanel } from "components/meters/MeterConfigPanel";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useGridContext } from "contexts/GridContext";
import { Heading } from "components/common/Heading";
import { Tabs } from "components/common/Tabs";

export const MeterDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { onClose } = useDisclosure();
  const { openDrawer, selectedRows, setOpenDrawer } = useGridContext();

  const closeDrawer = () => setOpenDrawer(false);

  return (
    <Drawer isOpen={openDrawer} size="xl" onClose={onClose}>
      <DrawerOverlay
        backgroundColor="rgba(0,0,0,0.63)"
        zIndex="101"
        onClick={closeDrawer}
      />
      <DrawerContent
        zIndex="101"
        fontSize="xl"
        boxShadow="none"
        borderLeft="1px"
        borderLeftColor="ui.900"
      >
        <DrawerHeader borderBottom={"none"} p={4} backgroundColor="brand.1000">
          <Heading as="h6" color="ui.0">
            {selectedRows.length > 0 ? selectedRows[0].meter_id : ""}
          </Heading>
          <DrawerCloseButton mt={2} onClick={closeDrawer} color="ui.0" />
        </DrawerHeader>
        <DrawerBody overflowY="auto" px={0} my={5}>
          <Tabs
            bg="brand.1000"
            tabs={[
              {
                label: t("components.tabs.meterConfigPanelHeading"),
                content: <MeterConfigPanel />,
              },
              {
                label: t("components.tabs.meterAlerts"),
                content: <div>Coming Soon!</div>,
              },
            ]}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
